import React from "react";

export default {
    path: '/admin/wechat',
    title: '微信',
    noPermissions: ['saige_users'],
    children: [
        {
            path: '/admin/wechat',
            title: '数据面板',
            icon: 'dashboard',
            exact: true,
            component: React.lazy(() => import('~/pages/Admin/Wechat/Dashboard'))
        },
        {
            path: '/admin/wechat/wechat-manage',
            title: '微信管理',
            icon: 'wechat',
            permissions: ['op_view_label','op_view_wechat_account','op_view_all_wechat_group', 'op_view_wechat_group','op_wechat_user_view'],
            children: [
                {
                    path: '/admin/wechat/wechat-manage/labels',
                    title: '标签',
                    permissions: ['op_view_label'],
                    component: React.lazy(() => import('~/pages/Admin/Wechat/WechatManage/Label'))
                },
                {
                    path: '/admin/wechat/wechat-manage/accounts',
                    title: '微信号',
                    permissions: ['op_view_wechat_account'],
                    component: React.lazy(() => import('~/pages/Admin/Wechat/WechatManage/Account'))
                },
                {
                    path: '/admin/wechat/wechat-manage/groups',
                    title: '微信群',
                    permissions: ['op_view_all_wechat_group', 'op_view_wechat_group'],
                    component: React.lazy(() => import('~/pages/Admin/Wechat/WechatManage/Group'))
                },
                {
                    path: '/admin/wechat/wechat-manage/users',
                    title: '微信用户',
                    permissions: ['op_wechat_user_view'],
                    component: React.lazy(() => import('~/pages/Admin/Wechat/WechatManage/User'))
                },
            ]
        }
    ]
};