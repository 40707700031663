import React from 'react';

import {Form} from 'antd';

import {XLoader} from '~/components';
import './style.scss';
import XComponent from "~/components/Common/XComponent";

@Form.create()
class Login extends XComponent {

    constructor(props) {
        super(props);
        window.codepku.redirectSSO();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
            }
        });
    };

    render() {
        return <div id="first-loading"><XLoader tip="正在跳转登录系统~~"/></div>;
    }
}

export default Login;