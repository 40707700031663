import React from 'react';
import XErrorPage from "~/components/XErrorPage";
import XComponent from "~/components/Common/XComponent";

class NotPermission extends XComponent {

    render() {
        return <XErrorPage title='403' desc='抱歉，你无权访问此页面' buttons={[
            {
                title: '返回运营中心',
                onClick: () => {
                    window.location.replace('/');
                },
                type: 'primary'
            },
            {
                title: '返回官网后台',
                href: (process.env.REACT_APP_SSO_HTTPS === 'true' ? 'https' : 'http')
                    + '://' + process.env.REACT_APP_SSO_HOST + '/admin'
            },
            {
                title: '返回官网',
                href: (process.env.REACT_APP_SSO_HTTPS === 'true' ? 'https' : 'http')
                    + '://' + process.env.REACT_APP_SSO_HOST
            }
        ]}/>
    }
}

export default NotPermission;