/**
 * 来源组件
 * Created by john
 * Date: 2019-03-28
 * Email: maiqingqiang@gmail.com
 * Time: 18:30
 */
import React, {FunctionComponent, memo, useEffect} from 'react';
import {Cascader} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {getActivity, getActivityActions} from '~/store/modules/entities/activity';
import {CascaderOptionType} from 'antd/lib/cascader';

const ActivityCascader: FunctionComponent = (props) => {

    const dispatch = useDispatch();
    const activityStore: any = useSelector(getActivity);
    const activityActions: any = getActivityActions(dispatch);

    useEffect(() => {
        activityActions.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = (selectedOptions?: CascaderOptionType[]) => {
        const targetOption = selectedOptions![selectedOptions!.length - 1];
        targetOption.loading = true;
        activityActions.update(targetOption.id, targetOption);
    };

    return <Cascader
        placeholder='来源选择'
        options={activityStore.payload}
        loadData={loadData}
        fieldNames={{label: 'title', value: 'id', children: 'next'}}
        changeOnSelect
        {...props}
    />
};

export default memo(ActivityCascader);