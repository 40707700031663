/**
 * XComponent
 * Created by john
 * Date: 2019-03-19
 * Email: maiqingqiang@gmail.com
 * Time: 20:36
 */
import React from 'react';

class XComponent extends React.PureComponent {

}

export default XComponent;
