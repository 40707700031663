/**
 * index
 * Created by john
 * Date: 2019-01-31
 * Email: maiqingqiang@gmail.com
 * Time: 15:26
 */
import React from 'react';
import {Modal, Spin} from 'antd';
import XComponent from "~/components/Common/XComponent";

class XModal extends XComponent {
    render() {

        const {isLoading, children, ...modalProps} = this.props;

        return <Modal {...modalProps}>
            <Spin spinning={isLoading}>
                {children}
            </Spin>
        </Modal>;
    }
}

export default XModal;