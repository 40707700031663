import React, {FunctionComponent, memo, useState} from 'react';
import {Icon, Layout, Menu, Input, Switch, Popover} from 'antd';
import {RouteComponentProps} from 'react-router';
import {useSelector} from 'react-redux';
import {getUser} from '~/store/modules/entities/user';
import {NavLink, withRouter} from 'react-router-dom';
import styles from './style.module.scss';
import classNames from 'classnames';

const Sider: FunctionComponent<RouteComponentProps & {
    className: string;
    menus: [];
}> = ({className, location, menus}) => {
    const user = useSelector(getUser);
    const [currentMenu, setMenus] = useState(menus || []);
    const [mode, setMode] = useState<'vertical' | 'inline'>('vertical');
    const [collapsed, setCollapsed] = useState(false);

    const iconNode = (icon: string) => {
        if (icon) {
            return <Icon type={icon}/>;
        }
    };

    /**
     * 渲染菜单
     * @param data
     * @returns {*}
     */
    const renderMenu = (data: []) => {
        return data.map(
            (
                item: {
                    notMenu: boolean;
                    children: [];
                    path: string;
                    icon: string;
                    permissions: [];
                    noPermissions: [];
                    title: string;
                },
                index
            ) => {
                if (
                    !item.notMenu &&
                    (window as any).codepku.checkPermission(item.permissions, user)
                ) {
                    if ((window as any).codepku.checkNoPermissions(item.noPermissions, user))
                        return null;
                    if (item.children) {
                        return (
                            <Menu.SubMenu
                                key={item.path}
                                title={
                                    <span>
                                        {iconNode(item.icon)}
                                        <span>{item.title}</span>
                                    </span>
                                }
                            >
                                {renderMenu(item.children)}
                            </Menu.SubMenu>
                        );
                    } else {
                        return (
                            <Menu.Item key={item.path}>
                                <NavLink to={item.path}>
                                    <span>
                                        {iconNode(item.icon)}
                                        <span>{item.title}</span>
                                    </span>
                                </NavLink>
                            </Menu.Item>
                        );
                    }
                }
                return null;
            }
        );
    };

    /**
     * 搜索
     * @param keyword
     */
    const onSearch = (keyword: string) => {
        if (keyword) {
            const searchRoutes = filter(currentMenu, keyword);
            setMenus(searchRoutes);
        } else {
            setMenus(menus);
        }
    };

    /**
     * 过滤路由
     * @param menu
     * @param keyword
     */
    const filter: any = (menu: any, keyword: string) => {
        return menu.filter((value:any) => {
            return value.title.search(keyword) !== -1 || (value.children && JSON.stringify(value.children).search(keyword) !== -1);
        });
    };

    /**
     * 点击搜索对象
     */
    const onSearchMenu = () => {
        if (collapsed) setCollapsed(false);
    };

    /**
     * 点击切换模式
     */
    const onChangeMode = (val: boolean | string) => {
        setMode(val ? 'inline' : 'vertical')
    };

    return (
        <Layout.Sider
            width={'220px'}
            className={classNames(styles['x-sider'], className)}
            style={{background: '#fff'}}
            collapsible
            collapsed={collapsed}
            onCollapse={setCollapsed}
        >
            <Menu
                className={styles['menu']}
                mode={mode}
                inlineIndent={14}
                style={{height: '100%', borderRight: 0}}
                // defaultOpenKeys={(window as any).codepku.urlToList(location.pathname)}
                defaultSelectedKeys={(window as any).codepku.urlToList(location.pathname)}
            >
                <div className={styles['menu-actions']}>
                    <div className={styles['menu-search']} onClick={onSearchMenu}>
                        {collapsed?<Icon type="search" />:<Input.Search
                            placeholder="搜索菜单"
                            onSearch={onSearch}
                            allowClear
                        />}
                    </div>
                    <Popover
                        content={
                        <>菜单模式：<Switch onChange={onChangeMode} checkedChildren="内嵌" unCheckedChildren="垂直" /></>
                    }>
                        <Icon type="more" style={{fontSize: '16px', paddingRight: '10px'}}/>
                    </Popover>
                </div>
                {renderMenu(currentMenu)}
            </Menu>
        </Layout.Sider>
    );
};

export default withRouter(memo(Sider));
