import React from 'react';
import nprogress from 'nprogress';
import {Route} from 'react-router-dom';
import XComponent from "~/components/Common/XComponent";

class XRoute extends XComponent {

    constructor(props) {
        super(props);
        // try {
        //     const pathname = this.props.location.pathname.replace(/\//g, '%');
        //     window.apm.startTransaction(pathname, 'spa-page-load');
        // } catch (e) {
        //
        // }

        nprogress.start()
    }

    componentDidMount() {
        nprogress.done()
    }

    render() {
        return <Route {...this.props} />;
    }
}

export default XRoute;
