/**
 * common
 * Created by john
 * Date: 2019-01-28
 * Email: maiqingqiang@gmail.com
 * Time: 18:40
 */
import * as api from '~/configs/api';
import http from "~/utils/http";

// SSO 检查是否登录
export const authCheck = () => http.get(api.AUTH_CHECK);

// 获取当前登录用户
export const fetchMe = () => http.get(api.ME);

// 退出登录
export const logout = () => http.get(api.LOGOUT);
