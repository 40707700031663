import React, {FunctionComponent, memo} from 'react';
import {XRoute} from "../index";
import {useSelector} from "react-redux";
import {getCommon} from "../../store/modules/common";
import {getUser} from "../../store/modules/entities/user";
import {Redirect, RouteComponentProps, RouteProps} from "react-router";

const PrivateRoute: FunctionComponent<RouteProps & {
    permissions: [],
    noPermissions: []
}> = ({
          permissions,
          noPermissions,
          component,
          render,
          ...rest
      }) => {
    const common = useSelector(getCommon);
    const user = useSelector(getUser);

    if (!common.isLogin) {
        return (
            <XRoute
                {...rest}
                render={(props: RouteComponentProps) => (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: props.location}
                        }}
                    />)}
            />
        );
    }

    if (!(window as any).codepku.checkPermission(permissions, user) || (window as any).codepku.checkNoPermissions(noPermissions, user)) {
        return (
            <XRoute
                {...rest}
                render={(props: RouteComponentProps) => (
                    <Redirect
                        to={{
                            pathname: "/403",
                            state: {from: props.location}
                        }}
                    />)}
            />
        );
    }

    const Component: any = component;

    return (
        <XRoute
            {...rest}
            render={render || ((props: RouteComponentProps) => <Component {...props} />)}
        />
    );
};

export default memo(PrivateRoute);