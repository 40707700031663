/**
 * article
 * Created by john
 * Date: 2019-07-22
 * Email: maiqingqiang@gmail.com
 * Time: 17:06
 */
import http from '~/utils/http';
import {ARTICLE} from '~/configs/api';

/**
 * 获取文章列表
 * @returns {Promise<any>}
 * @param params
 */
export const getArticles = (params = {}) => http.get(`${ARTICLE}`, params);

/**
 * 获取文章信息
 * @param id
 * @returns {Promise<any>}
 */
export const getSingleArticle = (id) => http.get(`${ARTICLE}/${id}`);

/**
 * 更新文章
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export const updateArticle = (id, params = {}) => http.put(`${ARTICLE}/${id}`, params);

/**
 * 创建文章
 * @param params
 * @returns {Promise<any>}
 */
export const createArticle = (params = {}) => http.post(`${ARTICLE}`, params);

/**
 * 创建文章
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export const deleteArticle = (id, params = {}) => http.delete(`${ARTICLE}/${id}`, params);


/**
 * 获取模块
 * @returns {Promise<any>}
 * @param params
 */
export const getModules = (params = {}) => http.get(`${ARTICLE}/modules`, params);

/**
 * 获取模块信息
 * @param id
 * @returns {Promise<any>}
 */
export const getSingleModule = (id) => http.get(`${ARTICLE}/modules/${id}`);

/**
 * 获取模块分类
 * @param params
 * @returns {Promise<any>}
 */
export const getTypes = (params = {}) => http.get(`${ARTICLE}/types`, params);

/**
 * 获取分类信息
 * @param id
 * @returns {Promise<any>}
 */
export const getSingleType = (id) => http.get(`${ARTICLE}/type/${id}`);

/**
 * 更新分类信息
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export const updateType = (id, params) => http.put(`${ARTICLE}/type/${id}`, params);

/**
 * 删除分类
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export const deleteType = (id, params) => http.delete(`${ARTICLE}/type/${id}`, params);

/**
 * 创建分类
 * @param params
 * @returns {Promise<any>}
 */
export const createType = (params) => http.post(`${ARTICLE}/type`, params);


/**
 * 获取关键词
 * @returns {Promise<any>}
 * @param params
 */
export const getKeywords = (params = {}) => http.get(`${ARTICLE}/keywords`, params);

/**
 *
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export const updateKeyword = (id, params) => http.put(`${ARTICLE}/keyword/${id}`, params);

/**
 * 更新栏目信息
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export const updateModule = (id, params) => http.put(`${ARTICLE}/module/${id}`, params);

/**
 * 创建栏目
 * @param params
 * @returns {Promise<any>}
 */
export const createModule = (params) => http.post(`${ARTICLE}/module`, params);
