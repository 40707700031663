/**
 * index
 * Created by john
 * Date: 2019-07-12
 * Email: maiqingqiang@gmail.com
 * Time: 18:09
 */
import {combineReducers} from 'redux';
import entities from './entities';
// import pages from './pages';
import common from './common';

export default combineReducers({
    entities,
    // pages,
    common
});