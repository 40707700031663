/**
 * cos
 * Created by john
 * Date: 2019-07-17
 * Email: maiqingqiang@gmail.com
 * Time: 23:19
 */
import {getCosTempKeys} from "~/services/admin/common";
import COS from 'cos-js-sdk-v5';
import {message} from 'antd';

// const cos = new COS({
//     getAuthorization: async (options, callback) => {
//         const hide = message.loading('正在获取上传权限', 0);
//         try {
//             const response = await getCosTempKeys();
//             if (response.isSuccess()) {
//                 const {credentials, expiredTime} = response.data;
//                 const {tmpSecretId, tmpSecretKey, sessionToken} = credentials;
//                 callback({
//                     TmpSecretId: tmpSecretId,
//                     TmpSecretKey: tmpSecretKey,
//                     XCosSecurityToken: sessionToken,
//                     ExpiredTime: expiredTime,
//                 })
//             } else {
//                 message.error(response.message);
//             }
//         } catch (e) {
//             message.error('获取临时秘钥失败');
//         }
//         hide();
//     }
// });

let cos;
const initClient = () => {
    return new COS({
        getAuthorization: async (options, callback) => {
            const hide = message.loading('正在获取上传权限', 0);
            try {
                const response = await getCosTempKeys();
                if (response.isSuccess()) {
                    const {credentials, expiredTime} = response.data;
                    const {tmpSecretId, tmpSecretKey, sessionToken} = credentials;
                    callback({
                        TmpSecretId: tmpSecretId,
                        TmpSecretKey: tmpSecretKey,
                        XCosSecurityToken: sessionToken,
                        ExpiredTime: expiredTime,
                    })
                } else {
                    message.error(response.message);
                }
            } catch (e) {
                message.error('获取临时秘钥失败');
            }
            hide();
        }
    });
};
/**
 * 分块上传
 * @param params
 * @returns {Promise<any>}
 */
export const sliceUploadFile = (params) => {
    return new Promise((resolve, reject) => {
        if (!cos) {
            cos = initClient();
        }
        cos.sliceUploadFile(params, (err, data) => {
            if (err) {
                reject(err)
            } else {
                resolve(data)
            }
        });
    })
};

export default cos;