/**
 * 群组级联组件
 * Created by john
 * Date: 2019-03-28
 * Email: maiqingqiang@gmail.com
 * Time: 18:30
 */
import React from 'react';
import {Cascader} from 'antd';
import isEmpty from 'lodash/isEmpty';
import * as wechatManageSevice from "~/services/wechatManage";
import XComponent from "~/components/Common/XComponent";

class GroupCascader extends XComponent {

    state = {
        options: []
    };

    componentDidMount() {
        const that = this;

        wechatManageSevice.getWechatCasGroups().then(response => {

            let options = response.data.map((item) => {
                return {...item, disabled: isEmpty(item.wechat_groups)}
            });

            that.setState({options: [...that.state.options, ...options]});
        });
    }

    onChange = (value, selectedOptions) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        this.props.onChange(targetOption ? targetOption.id : '');
    };

    render() {

        let otherProps = {};

        otherProps.defaultValue = Array.isArray(this.props['data-__meta'].initialValue) ? this.props['data-__meta'].initialValue : [this.props['data-__meta'].initialValue];

        if ('value' in this.props && !this.props.value) {
            otherProps.value = [''];
            delete otherProps.defaultValue;
        }

        return <Cascader
            {...otherProps}
            placeholder='群组选择'
            options={this.state.options}
            onChange={this.onChange}
            fieldNames={{label: 'name', value: 'id', children: 'wechat_groups'}}
            changeOnSelect
        />
    }
}

export default GroupCascader;