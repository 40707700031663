/**
 * index
 * Created by john
 * Date: 2019-02-28
 * Email: maiqingqiang@gmail.com
 * Time: 16:36
 */
import React from 'react';
import {Button, Col, Form, Row} from 'antd';
import './style/x-search.scss';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import XForm from '../XForm';
import moment from 'moment';
import {Authorized, XDownloadButton} from '~/components';
import XComponent from '~/components/Common/XComponent';
import Event from '~/utils/event';
import {parse, stringify} from 'qs';
import {withRouter} from 'react-router-dom';
import './style/index.scss';

const queryString = require('query-string');

@withRouter
@Form.create()
class XSearch extends XComponent {
    static defaultProps = {
        items: []
    };

    state = {
        filters: {}
    };

    componentDidMount() {
        const that = this;
        // 传递外部可操作函数
        !this.props.onRef ||
        this.props.onRef({
            output: e => this.handleSearch(e, true),
            outputReset: e => this.refreshReset()
        });

        const reset = () => {
            that.props.form.resetFields();
        };

        Event.on('x-search-reset', reset);

        this.props.history.listen(() => {
            Event.removeListener('x-search-reset', reset);
        });

        const query = parse(this.props.location.search, {
            ignoreQueryPrefix: true
        });

        this.props.form.setFieldsValue(query);
    }

    /**
     * 筛选
     * @param e
     * @param output
     */
    handleSearch = (e, output = false) => {
        !e || e.preventDefault();
        const that = this;
        that.props.form.validateFields((errors, values) => {
            Object.keys(values).forEach(value => {
                if (values[value] instanceof Array && values[value][0] instanceof moment) {
                    values[value] = values[value].map(value =>
                        value.format(that.props.timeType ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD')
                    );
                }
            });

            that.setState({
                filters: values
            });

            if (!isEmpty(values)) that.props.onSearch({}, values);

            if (output) {
                const string = queryString.parseUrl(this.props.outputUrl);
                values = {...values,...string.query};
               window.location = string.url + '?' + stringify(values);
            }
        });
    };

    /**
     * 刷新重置筛选
     */
    refreshReset() {
        this.props.form.resetFields();
    }

    /**
     * 重置筛选
     */
    handleSearchReset = e => {
        this.props.form.resetFields();
        this.handleSearch(e);
    };

    render() {
        const that = this;
        const {
            form: {getFieldDecorator},
            items,
            className,
            output,
            outputPermissions
        } = this.props;
        return (
            <>
                {isEmpty(items) || (
                    <div className={`table-search ${className || ''}`}>
                        <Form onSubmit={that.handleSearch} layout="inline">
                            <Row gutter={{md: 8, lg: 24, xl: 48}}>
                                {items.map(({label, ...props}) => {
                                    props.formType = props.type;

                                    if (props.formType === 'radioButton') {
                                        props.onSubmit = that.handleSearch;
                                    }

                                    delete props.type;

                                    let opts = {initialValue: props.defaultValue};

                                    if (props.formType === 'dateRangePicker') {
                                        opts = {
                                            ...opts,
                                            normalize: value => {
                                                if (value) {
                                                    return value.map(val => moment(val));
                                                }
                                                return value;
                                            }
                                        };
                                    }

                                    return (
                                        <Col
                                            className="table-search-item"
                                            md={props.width || 8}
                                            sm={24}
                                            key={`col-${props.name}`}
                                        >
                                            <Form.Item key={`item-${props.name}`} label={label}>
                                                {getFieldDecorator(props.name, opts)(
                                                    <XForm {...props} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    );
                                })}

                                <Col className="table-search-item" md={8} sm={24}>
                                    <>
                                        <Button type="primary" htmlType="submit">
                                            查询
                                        </Button>
                                        <Button
                                            style={{marginLeft: 8}}
                                            onClick={that.handleSearchReset}
                                        >
                                            重置
                                        </Button>
                                        {outputPermissions && output ? (
                                            <Authorized permissions={[outputPermissions]}>
                                                <XDownloadButton
                                                    style={{marginLeft: 8}}
                                                    onClick={e => this.handleSearch(e, true)}
                                                >
                                                    导出
                                                </XDownloadButton>
                                            </Authorized>
                                        ) : (
                                            ''
                                        )}
                                        {output && !outputPermissions ? (
                                            <XDownloadButton
                                                style={{marginLeft: 8}}
                                                onClick={e => this.handleSearch(e, true)}
                                            >
                                                导出
                                            </XDownloadButton>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                )}
            </>
        );
    }
}

XSearch.propTypes = {
    onSearch: PropTypes.func,
    output: PropTypes.bool,
    outputPermissions: PropTypes.string,
    outputUrl: PropTypes.string,
    onRef: PropTypes.func
};

export default XSearch;
