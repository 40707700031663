import entityFactory from './entityFactory';
import {bindActionCreators} from "redux";

const namespace = 'lanqiaoConfig';

export const {types, actions, reducer} = entityFactory(namespace, {payload: []}, {}, {
    [`${namespace}/SUCCESS`]: (state, action) => {
        return {
            ...state,
            isFetching: false,
            isCompleted: true,
            payload: action.payload
        }
    }
});

export default reducer;

export const getLanqiaoConfig = state => state.entities.lanqiaoConfig;

export const getLanqiaoConfigActions = dispatch => bindActionCreators(actions, dispatch);