/**
 * XEditTableCell
 * Created by john
 * Date: 2019-03-22
 * Email: maiqingqiang@gmail.com
 * Time: 15:46
 */
import React from 'react';
import XEditTableContext from './XEditTableContext';
import {Form} from 'antd';
import {XForm} from "~/components/index";
import XComponent from "~/components/Common/XComponent";

class XEditTableCell extends XComponent {

    render() {

        const {
            editing,
            dataIndex,
            record,
            formOptions,
            ...restProps
        } = this.props;
        return (
            <XEditTableContext.Consumer>
                {(form) => {
                    const {getFieldDecorator} = form;
                    let val;
                    if (formOptions) {
                        val = typeof record[formOptions.name] === 'number' ? `${record[formOptions.name]}` : record[formOptions.name];
                    }
                    return (
                        <td {...restProps}>
                            {editing ? (
                                <Form.Item style={{margin: 0}}>
                                    {getFieldDecorator(formOptions.name, {
                                        rules: formOptions.rules,
                                        initialValue: val || record[formOptions.name] || '',
                                    })(<XForm {...formOptions} />)}
                                </Form.Item>
                            ) : restProps.children}
                        </td>
                    );
                }}
            </XEditTableContext.Consumer>
        );
    }
}

export default XEditTableCell;