/**
 * codepku
 * Created by john
 * Date: 2019-01-29
 * Email: maiqingqiang@gmail.com
 * Time: 15:01
 */

import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';

/**
 * 重定向登录系统
 */
export const redirectSSO = (force = false) => {
    const redirectUri = window.location.href;

    setTimeout(() => {
        window.location.href = (process.env.REACT_APP_SSO_HTTPS === 'true' ? 'https' : 'http')
            + '://' + process.env.REACT_APP_SSO_HOST + (!force ? '/login?redirect_uri=' + encodeURIComponent(redirectUri) : '');
    }, 1500);
};

/**
 * 检查当前登录用户是否有权
 * @param permissions
 * @param user
 * @returns {boolean}
 */
export const checkPermission = (permissions, user) => {
    return !(!isEmpty(permissions) &&
        !user.is_super &&
        isEmpty(intersection(permissions, user.permissions)));
};

export const checkHasPermission = (permission, user) => {
    return !isEmpty(user.permissions) && user.permissions.indexOf(permission) > 0;
};

export const checkNoPermissions = (permissions, user) => {
    return !isEmpty(permissions) && !isEmpty(intersection(permissions, user.permissions));
};


/**
 * 逐级分解url
 * @param url
 * @returns {*}
 */
export const urlToList = (url) => {
    const urllist = url.split('/').filter(i => i);
    let list = urllist.map((urlItem, index) => `/${urllist.slice(0, index + 1).join('/')}`);
    if (list.length >= 3) list = list.splice(2, 2);
    return list;
};

/**
 * 年级
 * @returns {*[]}
 */
export const getGrades = () => {
    return [{
        key: '学前',
        val: -1
    }, {
        key: '未知',
        val: 0
    }, {
        key: '一年级',
        val: 1
    }, {
        key: '二年级',
        val: 2
    }, {
        key: '三年级',
        val: 3
    }, {
        key: '四年级',
        val: 4
    }, {
        key: '五年级',
        val: 5
    }, {
        key: '六年级',
        val: 6
    }, {
        key: '初一',
        val: 7
    }, {
        key: '初二',
        val: 8
    }, {
        key: '初三',
        val: 9
    }, {
        key: '高一',
        val: 10
    }, {
        key: '高二',
        val: 11
    }, {
        key: '高三',
        val: 12
    }, {
        key: '成人',
        val: 13
    }, {
        key: '未上学',
        val: 14
    }, {
        key: '小班',
        val: 15
    }, {
        key: '中班',
        val: 16
    }, {
        key: '大班',
        val: 17
    }];
};