/**
 * index
 * Created by john
 * Date: 2019-04-02
 * Email: maiqingqiang@gmail.com
 * Time: 17:34
 */
import React from 'react';
import {Button} from "antd";
import {Footer} from "~/components/Layouts";
import PropTypes from "prop-types";
import styles from './style/index.module.scss';
import XComponent from "~/components/Common/XComponent";
import error from '~/assets/error.png';

class XErrorPage extends XComponent {

    render() {
        const {title, image, desc, buttons} = this.props;

        return <div className={styles['error-page']}>
            <div className={styles['error-page-content']}>
                <div><img src={image || error} alt='error'/></div>
                <div>
                    <h1 className={styles['error-page-title']}>{title}</h1>
                    <div className={styles['error-page-desc']}>{desc}</div>
                    <div className={styles['error-page-btn']}>
                        {buttons.map(({title, ...btnProps}, index) =>
                            <Button key={index} {...btnProps}>{title}</Button>
                        )}
                    </div>
                </div>
            </div>

            <Footer>
                Copyright ©2019 编玩边学 | 运营中心
            </Footer>
        </div>;
    }
}

XErrorPage.propTypes = {
    title: PropTypes.string,
    image: PropTypes.string,
    desc: PropTypes.string,
    buttons: PropTypes.array
};

export default XErrorPage;
