/**
 * index
 * Created by john
 * Date: 2019-08-01
 * Email: maiqingqiang@gmail.com
 * Time: 16:59
 */
import React from 'react';
import XComponent from "~/components/Common/XComponent";
import WangEditor from 'wangeditor';
import Moment from 'moment';
import {sliceUploadFile} from '~/utils/cos';
import {Icon, notification, Progress} from "antd";
import './style/index.scss';

class XEditor extends XComponent {
    state = {
        fullscreen: false
    };

    componentDidMount() {
        const {onChange, value} = this.props;
        this.editor = new WangEditor(this.editorRef);
        this.editor.customConfig.onchange = html => {
            onChange(html);
        };

        this.editor.customConfig.pasteFilterStyle = false;

        this.editor.customConfig.customUploadImg = (files, insert) => {
            files.map(value => {
                const key = `editor-upload-image-${value.name}`;
                sliceUploadFile({
                    Bucket: process.env.REACT_APP_COSV5_BUCKET,
                    Region: process.env.REACT_APP_COSV5_REGION,
                    Key: `/article/${Moment().format('YYYYMMDDhhmmss')}-${value.name}`,
                    Body: value,
                    onProgress: ({total, loaded}) => {
                        const percent = Math.round(loaded / total * 100);
                        notification.open({
                            key,
                            message: `${percent >= 100 ? '完成' : '正在'}上传图片:${value.name}`,
                            description: <Progress
                                strokeColor={{
                                    '0%': '#108ee9',
                                    '100%': '#87d068',
                                }}
                                percent={percent.toFixed(2)}
                            />,
                            icon: <Icon type="upload" style={{color: '#108ee9'}}/>,
                            duration: 0
                        });
                    }
                }).then(response => {
                    this.timer = setTimeout(() => {
                        notification.close(key)
                    }, 1500);
                    insert(`//${response.Location}`);
                });

                return true;
            });
        };

        this.editor.create();
        this.editor.txt.html(value);
        this.editorEle = document.querySelector('.x-editor');
        this.toolbar = document.querySelector('.w-e-toolbar');
        this.textWrap = document.querySelector('.w-e-text');

        this.initPreview();
        this.initFullscreen();
        this.initClearStyle();
        this.initClearFormat();
        this.initClearAll();
    }

    /**
     * 初始化全屏功能
     */
    initFullscreen = () => {
        const that = this;
        const open = '全屏';
        const close = '关闭全屏';
        let isFullscreen = false;
        const fullscreenBtn = this.createToolbar(open);
        this.toolbar.appendChild(fullscreenBtn);
        fullscreenBtn.addEventListener('click', _ => {
            that.editorEle.className = isFullscreen ? 'x-editor' : 'x-editor fullscreen-editor';
            fullscreenBtn.innerHTML = isFullscreen ? open : close;
            isFullscreen = !isFullscreen
        }, false)
    };

    /**
     * 初始化过滤样式功能
     */
    initClearStyle = () => {
        const that = this;
        const {onChange} = this.props;
        const clearStyleBtn = this.createToolbar('过滤样式');
        this.toolbar.appendChild(clearStyleBtn);
        clearStyleBtn.addEventListener('click', _ => {
            Array.from(that.textWrap.getElementsByTagName('*')).map(item => {
                if (item.style) {
                    item.removeAttribute('style')
                }
                if (item.className) {
                    item.removeAttribute('class')
                }
                return true;
            });
            const html = that.editor.txt.html()
                .replace(/ style="(.*?)"/ig, '')
                .replace(/ style='(.*?)'/ig, '')
                .replace(/ class="(.*?)"/ig, '')
                .replace(/ class='(.*?)'/ig, '')
                .replace(/ lang="(.*?)"/ig, '')
                .replace(/ face="(.*?)"/ig, '')
                .replace(/ size="(.*?)"/ig, '')
                .replace(/ align="(.*?)"/ig, '')
                .replace(/ color="(.*?)"/ig, '')
                .replace(/ border="(.*?)"/ig, '')
                .replace(/<style>[\s\S]*?<\/style>/ig, '')
                .replace(/<o:p>[\s\S]*?<\/o:p>/ig, '')
                .replace(/<!--[\s\S]*?-->/ig, '');

            that.editor.cmd.do('insertHTML', html);
            onChange(html);
            that.editor.txt.html(html);
        }, false)
    };

    /**
     * 初始化清空功能
     */
    initClearAll = () => {
        const that = this;

        let clearAllBtn = this.createToolbar('清空');
        this.toolbar.appendChild(clearAllBtn);
        clearAllBtn.addEventListener('click', _ => {
            that.editor.txt.clear();
            that.editor.cmd.do('insertHTML', '');
            that.props.onChange('');
        }, false)
    };

    /**
     * 初始化清空功能
     */
    initPreview = () => {
        const that = this;
        const open = '预览';
        const close = '关闭预览';
        let isPreview = false;
        const previewBtn = this.createToolbar(open);
        this.toolbar.appendChild(previewBtn);
        previewBtn.addEventListener('click', _ => {
            previewBtn.innerHTML = isPreview ? open : close;
            isPreview = !isPreview;
            that.props.onPreview(isPreview);
        }, false);
    };

    /**
     * 初始化清除格式
     */
    initClearFormat = () => {
        const that = this;
        const {onChange} = this.props;
        let clearFormatBtn = this.createToolbar('清除格式');
        this.toolbar.appendChild(clearFormatBtn);
        clearFormatBtn.addEventListener('click', _ => {
            const str = that.editor.txt.html();
            const arr = str.split(/(<[^>]*>([\s\S]*?)|<[^>]*><\/[^>]*>)/);

            const formatContent = arr.filter(item => {
                return item !== '' && !item.includes('</')
            }).filter(item => {
                return !item.includes('<') || item.startsWith('<img')
            }).map(item => {
                return `<p>${item}</p>`
            }).join('');
            onChange(formatContent);
            that.editor.txt.html(formatContent)
        }, false)
    };

    /**
     * 创建菜单按钮
     * @param html
     * @returns {HTMLDivElement}
     */
    createToolbar = (html) => {
        const btn = document.createElement('div');
        btn.className = 'w-e-menu';
        btn.style.zIndex = '10001';
        btn.innerHTML = html;
        return btn
    };

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        return <div className='x-editor'
                    ref={ref => this.editorRef = ref}/>
    }
}

export default XEditor;