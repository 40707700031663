import React from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import XComponent from "~/components/Common/XComponent";
import styles from './style.module.scss';

/**
 * 粒子波浪
 * @author John Mai
 */
class Waves extends XComponent {

    static defaultProps = {
        separation: 100,
        amountx: 50,
        amounty: 50,
        color: 'e1e1e1'
    };

    state = {};

    data = {
        container: null, // 容器
        camera: null, // 相机
        scene: null, // 场景
        renderer: null, // 渲染器
        particles: 0, // 粒子
        count: 0, // 粒子
        windowHalfX: window.innerWidth / 2,
        windowHalfY: window.innerHeight / 2,
        mouseX: 85,
        mouseY: -342,
    };

    componentDidMount() {
        this.init();
        this.animate()
    }

    render() {
        return <div className={styles.myWaves} style={{width: '100%'}}>{this.props.children}</div>;
    }

    init() {
        this.data.container = document.querySelector(`.${styles.myWaves}`);

        this.data.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
        this.data.camera.position.z = 1000;
        this.data.scene = new THREE.Scene();
        //
        let numParticles = this.props.amountx * this.props.amounty;
        let positions = new Float32Array(numParticles * 3);
        let scales = new Float32Array(numParticles);
        let i = 0, j = 0;
        for (let ix = 0; ix < this.props.amountx; ix++) {
            for (let iy = 0; iy < this.props.amounty; iy++) {
                positions[i] = ix * this.props.separation - ((this.props.amountx * this.props.separation) / 2); // x
                positions[i + 1] = 0; // y
                positions[i + 2] = iy * this.props.separation - ((this.props.amounty * this.props.separation) / 2); // z
                scales[j] = 1;
                i += 3;
                j++;
            }
        }
        let geometry = new THREE.BufferGeometry();
        geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3));
        geometry.addAttribute('scale', new THREE.BufferAttribute(scales, 1));

        let color = this.props.color.replace(/#/g, '');

        let material = new THREE.ShaderMaterial({
            uniforms: {
                color: {value: new THREE.Color(parseInt('0x' + color))},
            },
            vertexShader: 'attribute float scale; void main() { vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 ); gl_PointSize = scale * ( 300.0 / - mvPosition.z ); gl_Position = projectionMatrix * mvPosition; }',
            fragmentShader: 'uniform vec3 color; void main() { if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard; gl_FragColor = vec4( color, 1.0 ); }'
        });
        //
        this.data.particles = new THREE.Points(geometry, material);
        this.data.scene.add(this.data.particles);
        //
        this.data.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
        this.data.renderer.setPixelRatio(window.devicePixelRatio);
        this.data.renderer.setSize(window.innerWidth, window.innerHeight, false);
        this.data.container.appendChild(this.data.renderer.domElement);
        document.addEventListener('mousemove', this.onDocumentMouseMove, false);
        document.addEventListener('touchstart', this.onDocumentTouchStart, false);
        document.addEventListener('touchmove', this.onDocumentTouchMove, false);
        //
        window.addEventListener('resize', this.onWindowResize, false);
    }

    onWindowResize = () => {

        this.data.windowHalfX = window.innerWidth / 2;
        this.data.windowHalfY = window.innerHeight / 2;

        this.data.camera.aspect = window.innerWidth / window.innerHeight;
        this.data.camera.updateProjectionMatrix();

        this.data.renderer.setSize(window.innerWidth, window.innerHeight);

    };

    //

    onDocumentMouseMove = (event) => {

        this.data.mouseX = event.clientX - this.data.windowHalfX;
        this.data.mouseY = event.clientY - this.data.windowHalfY;

    };

    onDocumentTouchStart = (event) => {

        if (event.touches.length === 1) {

            event.preventDefault();

            this.data.mouseX = event.touches[0].pageX - this.data.windowHalfX;
            this.data.mouseY = event.touches[0].pageY - this.data.windowHalfY;

        }

    };

    onDocumentTouchMove = (event) => {

        if (event.touches.length === 1) {

            event.preventDefault();

            this.data.mouseX = event.touches[0].pageX - this.data.windowHalfX;
            this.data.mouseY = event.touches[0].pageY - this.data.windowHalfY;

        }

    };

    animate = () => {

        requestAnimationFrame(this.animate);

        this.renderWaves();
    };

    renderWaves = () => {

        this.data.camera.position.x += (this.data.mouseX - this.data.camera.position.x) * .05;
        this.data.camera.position.y += (-this.data.mouseY - this.data.camera.position.y) * .05;
        this.data.camera.lookAt(this.data.scene.position);
        let positions = this.data.particles.geometry.attributes.position.array;
        let scales = this.data.particles.geometry.attributes.scale.array;
        let i = 0, j = 0;
        for (let ix = 0; ix < this.props.amountx; ix++) {
            for (let iy = 0; iy < this.props.amounty; iy++) {
                positions[i + 1] = (Math.sin((ix + this.data.count) * 0.3) * 50) +
                    (Math.sin((iy + this.data.count) * 0.5) * 50);
                scales[j] = (Math.sin((ix + this.data.count) * 0.3) + 1) * 8 +
                    (Math.sin((iy + this.data.count) * 0.5) + 1) * 8;
                i += 3;
                j++;
            }
        }
        this.data.particles.geometry.attributes.position.needsUpdate = true;
        this.data.particles.geometry.attributes.scale.needsUpdate = true;
        this.data.renderer.render(this.data.scene, this.data.camera);
        this.data.count += 0.1;

    }

}

Waves.propTypes = {
    fov: PropTypes.number,
    color: PropTypes.string,
    separation: PropTypes.number,
    amountx: PropTypes.number,
    amounty: PropTypes.number,
};
export default Waves;
