/**
 * data
 * Created by john
 * Date: 2019-02-20
 * Email: maiqingqiang@gmail.com
 * Time: 11:06
 */

import http from '~/utils/http';
import {DATA, USER, OPERATE} from '~/configs/api';
import {COURSE, HOST, STUDY, TRAINAPI} from '~/configs/pubilc-class-api';

/**
 * MC 课程体验活动
 * @param params
 * @returns {Promise<any>}
 */
export const getMcWorldDatas = (params = {}) => http.get(`${DATA}/mc-world`, params);

/**
 * 导出 MC 课程体验活动表
 * @type {string}
 */
export const exportMcWorldDatas = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/mc-world/1`;

/**
 * 赛格数据
 * @param params
 * @returns {Promise<any>}
 */
export const getSaigeDatas = (params = {}) => http.get(`${DATA}/saige`, params);

/**
 * 赛格有效率
 * @returns {Promise<any>}
 */
export const getSaigeConversionRate = () => http.get(`${DATA}/saige-conversion-rate`);

/**
 * 获取赛格用户大课订单
 * @param userId
 * @returns {Promise<any>}
 */
export const getSystemOrderBySaige = userId => http.get(`${DATA}/system-order-by-saige/${userId}`);

/**
 * 更新赛格行
 * @param id
 * @param params
 * @param headers
 * @returns {Promise<any>}
 */
export const updateSaigeRow = (id, params, headers) =>
    http.put(`${DATA}/saige/${id}`, params, headers);

/**
 * 周年庆留资
 * @param params
 * @returns {Promise<any>}
 */
export const getFiveAnniversaryDatas = params => http.get(`${DATA}/five-anniversary`, params);

/**
 * 导出 周年庆留资
 * @type {string}
 */
export const exportFiveAnniversaryDatas = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/five-anniversary/1`;

/**
 * 蓝桥杯省赛报名表
 * @param params
 * @returns {Promise<any>}
 */
export const getLanQiaoNationalDatas = params => http.get(`${DATA}/lanqiao/national`, params);

/**
 * 导出 蓝桥杯省赛报名表
 * @type {string}
 */
export const exportLanQiaoNationalDatas = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/lanqiao/national/1`;

/**
 * 第十一届蓝桥杯省赛报名表
 * @param params
 * @returns {Promise<any>}
 */
export const elevenNationalDatas = params => http.get(`${DATA}/lanqiao/national2019`, params);

/**
 * 导出 第十一届蓝蓝桥杯省赛报名表
 * @type {string}
 */
export const exportelevenDatas = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/lanqiao/national2019/1`;

/**
 * 更新活动备注
 * @param id
 * @param params
 * @param headers
 * @returns {Promise<any>}
 */
export const updateLanQiaoNationalRow = (id, params, headers) =>
    http.put(`${DATA}/lanqiao/national/${id}`, params, headers);

/**
 * 转介绍打卡
 * @param params
 * @returns {Promise<any>}
 */
export const getReferralPunchDatas = params => http.get(`${DATA}/referral-punch`, params);

/**
 * 导出 转介绍打卡
 * @type {string}
 */
export const exportReferralPunchDatas = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/referral-punch/1`;

/**
 * 登录信息
 * @param params
 * @returns {Promise<any>}
 */
export const loginInfor = params => http.get(`${USER}/last_login_log`, params);

/**
 * 登录详情
 * @param params
 * @returns {Promise<any>}
 */
export const loginDetails = (id, params = {}) => http.get(`${USER}/login_log/${id}`, params);

/**
 * 导出 用户登录信息
 * @type {string}
 */
export const exportLoginInfo = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${USER}/last_login_log/1`;

/**
 * 导出 用户登录详情
 * @type {string}
 */
export const exportUserLoginDetails = userId =>
    `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${USER}/login_log/${userId}/1`;

/**
 * 蓝桥杯国赛集训营
 * @param params
 * @returns {Promise<any>}
 */
export const getTraining = params => http.get(`${DATA}/lanqiao/national/training`, params);

export const exportTraining = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/lanqiao/national/training/1`;
// export const exportTraining = params => http.get(`${DATA}/lanqiao/national/training`, params);

/**
 * 蓝桥杯国赛集报名科目
 * @param params
 * @returns {Promise<any>}
 */
export const enrolmentSubjects = params => http.get(`${DATA}/lanqiao/national/courses`, params);

/**
 * 引流课报名表
 * @param params
 * @returns {Promise<any>}
 */
export const getSignUp = params => http.get(`${DATA}/drainage/sign-up`, params);

/**
 * 2019夏校报名表单
 * @param params
 * @returns {Promise<any>}
 */
export const summerSchool = params => http.get(`${DATA}/summer-school`, params);

/**
 * 导出 夏校报名表单
 * @type {string}
 */
export const exportSummerSchool = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/summer-school/true`;

/**
 * 导出引流课报名表
 * @type {string}
 */
export const exportSignUp = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/drainage/sign-up/1`;

/**
 * admp5夏校数据
 * @param params
 * @returns {Promise<any>}
 */
export const getAdMp5 = params => http.get(`${DATA}/ad-mp5`, params);

/**
 * 导出admp5夏校数据
 * @type {string}
 */
export const exportAdMp5 = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/ad-mp5/1`;

/**
 * 公开课直播间列表
 * @param params
 * @returns {Promise<any>}
 */
export const directBroad = params => http.get(`${HOST}/public-class`, params);

/**
 * 公开课直播间老师列表
 * @param params
 * @returns {Promise<any>}
 */
export const teachersList = params => http.get(`${HOST}/public-class/schedule`, params);

/**
 * 新建公开课直播间
 * @param params
 * @returns {Promise<any>}
 */
export const newDirectBroad = params => http.post(`${HOST}/public-class`, params);

/**
 * 编辑公开课直播间
 * @param params
 * @returns {Promise<any>}
 */
export const editDirectBroad = (id, params) => http.put(`${HOST}/public-class/` + id, params);

/**
 * 师训直播间列表
 * @param params
 * @returns {Promise<any>}teacher
 */
export const teacherBroad = params => http.get(`${TRAINAPI}/train`, params);

/**
 * 师训直播间老师列表
 * @param params
 * @returns {Promise<any>}
 */
export const teacherList = params => http.get(`${TRAINAPI}/train/schedule`, params);

/**
 * 新建师训直播间
 * @param params
 * @returns {Promise<any>}
 */
export const newTeacherBroad = params => http.post(`${TRAINAPI}/train`, params);

/**
 * 编辑师训直播间
 * @param params
 * @returns {Promise<any>}
 */
export const editTeacherBroad = (id, params) => http.put(`${TRAINAPI}/train/` + id, params);

/**
 * cdn临时秘钥
 * @param params
 * @returns {Promise<any>}
 */
export const getCdnKey = params => http.get(`${STUDY}/personal/temp-secret`, params);

/**
 * 关联课程选课
 * @param params
 * @returns {Promise<any>}
 */
export const selectCourse = params => http.get(`${COURSE}/tool/select-course`, params);

/**
 * 引流课报导入校验
 * @param params
 * @returns {Promise<any>}
 */
export const orderImportVerify = params =>
    http.post(`${DATA}/drainage/order-import-verify`, params);

/**
 * 引流课导入
 * @param params
 * @returns {Promise<any>}
 */
export const orderImport = params => http.post(`${DATA}/drainage/order-import`, params);

/**
 * 2019儿童节数据
 * @param params
 * @returns {Promise<any>}
 */
export const getChildrenDay2019 = params => http.get(`${DATA}/children-day-2019`, params);

/**
 * 导出蓝桥杯选拔赛报名表
 * @param params
 * @returns {Promise<any>}
 */
export const trainingCampUrl = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/lanqiao/training_camp/1`;

/**
 * 引流课报导入校验
 * @param params
 * @returns {Promise<any>}
 */
export const exportChildrenDay2019 = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/children-day-2019/true`;

/**
 * 蓝桥杯选拔赛报名表
 * @param params
 * @returns {Promise<any>}
 */
export const trainingCamp = params => http.get(`${DATA}/lanqiao/training_camp`, params);

/**
 * 蓝桥杯国赛集训落地页
 * @param params
 * @returns {Promise<any>}
 */
export const trainingFloor = params => http.get(`${DATA}/lanqiao/training-floor`, params);

/**
 * 导出蓝桥杯国赛集训落地页
 * @param params
 * @returns {Promise<any>}
 */
export const exportTrainingFloor = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/lanqiao/training-floor/1`;

/**
 * SPO落地页
 * @param params
 * @returns {Promise<any>}
 */
export const spoFloor = params => http.get(`${DATA}/lanqiao/spo-floor`, params);

/**
 * 导出SPO落地页
 * @param params
 * @returns {Promise<any>}
 */
export const exportSpoFloor = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/lanqiao/spo-floor/1`;

/**
 * SPO 报名
 * @param params
 * @returns {Promise<any>}
 */
export const spoApply = params => http.get(`${DATA}/spo-apply`, params);

/**
 * 导出 SPO 报名
 * @param params
 * @returns {Promise<any>}
 */
export const exportSpoApply = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/spo-apply/1`;

/**
 * 二维码课程列表
 * @param params
 * @returns {Promise<any>}
 */
export const qrcodeCourses = params => http.get(`qrcode-courses`, params);

/**
 * 课程列表
 * @param params
 * @returns {Promise<any>}
 */
export const qrcodeCoursesList = params => http.get(`qrcode-courses/courses`, params);

/**
 * 创建人列表
 * @param params
 * @returns {Promise<any>}
 */
export const qrcodeCreators = params => http.get(`qrcode-courses/creators`, params);

/**
 * 创建二维码
 * @param params
 * @returns {Promise<any>}
 */
export const newQrcodeCode = params => http.post(`qrcode-courses`, params);

/**
 * 数据统计
 * @param params
 * @returns {Promise<any>}
 */
export const signupData = params => http.get(`qrcode-courses/signup-data`, params);

/**
 * 导出数据统计列表
 * @param params
 * @returns {Promise<any>}
 */
export const signupDataUrl = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/qrcode-courses/signup-data/1`;

/**
 * 销售负责人
 * @param params
 * @returns {Promise<any>}
 */
export const commonSalers = params => http.get(`common/salers`, params);

/**
 * 获取用户观看详情
 * @param activity_tag
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export const learnRecords = (activity_tag, id, params) =>
    http.get(`qrcode-courses/learn-records/` + activity_tag + '/' + id, params);

/**
 * 数据详情【一个二维码课程】
 * @param activity_tag
 * @param params
 * @returns {Promise<any>}
 */
export const signupDetail = (activity_tag, params) =>
    http.get(`qrcode-courses/signup-detail/` + activity_tag, params);

/**
 * 数据详情【一个二维码课程】
 * @returns {Promise<any>}
 * @param slug
 */
export const signupDetailUrl = slug =>
    `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/qrcode-courses/signup-detail/${slug}/1`;

/**
 * 获取用户订单详情
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export const orderDetail = (id, params) => http.get(`qrcode-courses/order-detail/` + id, params);

/**
 * 获取一个二维码课程详情
 * @returns {Promise<any>}
 * @param slug
 * @param params
 */
export const getQrCourses = (slug, params = {}) => http.get(`qrcode-courses/show/${slug}`, params);

/**
 * 留言反馈
 * @returns {Promise<any>}
 * @param slug
 * @param params
 */
export const feedback = (params = {}) => http.get(`gs/feedback-form-data`, params);

/**
 * 问卷调查
 * @returns {Promise<any>}
 * @param slug
 * @param params
 */
export const questionnaire = (params = {}) => http.get(`gs/questionnaire-form-data`, params);

/**
 * 选拔赛集训营落地页跟进情况
 * @returns {Promise<any>}
 * @param id
 * @param params
 */
export const crmCommunication = (id, params) =>
    http.get(`${DATA}/lanqiao/crm-communication/${id}`, params);

/**
 * spo跟进
 * @returns {Promise<any>}
 * @param id
 * @param params
 */
export const jwglCommunication = (id, params) =>
    http.get(`${DATA}/lanqiao/jwgl-communication/${id}`, params);

/**
 * 跟进话术筛选列表
 * @returns {Promise<any>}
 * @param params
 */
export const crmFollowSpeeches = params => http.get(`${DATA}/crm-follow-up-speeches`, params);

/**
 * 跟进话术筛选列表
 * @returns {Promise<any>}
 * @param params
 */
export const jwglFollowSpeeches = params => http.get(`${DATA}/jwgl-follow-up-speeches`, params);

/**
 * 1020迷你Minicode比赛报名表
 * @returns {Promise<any>}
 * @param params
 */
export const minicode = params => http.get(`${DATA}/minicode`, params);

/**
 * 1020迷你Minicode比赛报名表导出
 * @returns {Promise<any>}
 * @param slug
 */
export const minicodeUrl = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/operate/data/minicode/1`;

/**
 * 实名认证申请记录列表
 * @returns {Promise<any>}
 * @param params
 */
export const verifications = params => http.get(`${OPERATE}/verifications`, params);

/**
 * 审批实名认证申请
 * @returns {Promise<any>}
 * @param params
 */
export const verificationsPut = (id, params) => http.post(`${OPERATE}/verifications/${id}`, params);

/**
 * 第十一届选拔赛线上-191020
 * @returns {Promise<any>}
 * @param params
 */
export const eleventhOnline = params => http.get(`${DATA}/lanqiao/eleventh/online`, params);

/**
 * 第十一届选拔赛线下-191020
 * @returns {Promise<any>}
 * @param params
 */
export const eleventhOffline = params => http.get(`${DATA}/lanqiao/eleventh/offline`, params);

/**
 * 第十一届选拔赛线上-191215
 * @returns {Promise<any>}
 * @param params
 */
export const eleventhDecemberOnline = params =>
    http.get(`${DATA}/lanqiao/eleventh/december_online`, params);

/**
 * 第十一届选拔赛线下-191215
 * @returns {Promise<any>}
 * @param params
 */
export const eleventhDecemberOffline = params =>
    http.get(`${DATA}/lanqiao/eleventh/december_offline`, params);

/**
 * 第十一届选拔赛线下SCW-20191215
 * @returns {Promise<any>}
 * @param params
 */
export const eleventhDecemberCs = params => http.get(`${DATA}/lanqiao/eleventh/sc`, params);

/**
 * 第十一届选拔赛MC-191215
 * @returns {Promise<any>}
 * @param params
 * scratch_mc
 */
export const eleventhscratchMc = (status, params) =>
    http.get(`${DATA}/lanqiao/eleventh/${status}`, params);

/**
 * 第十一届选拔赛线上下编辑
 * @returns {Promise<any>}
 * @param params
 */
export const editEleventhOnline = (id, params) =>
    http.put(`${DATA}/lanqiao/eleventh/${id}`, params);

/**
 * 第十一届选拔赛线上-191020导出
 * @returns {Promise<any>}
 * @param slug
 */
export const eleventhOnlineUrl = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/lanqiao/eleventh/online/1`;

/**
 * 第十一届选拔赛线上-191215导出
 * @returns {Promise<any>}
 * @param slug
 */
export const eleventhDecemberOnlineUrl = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/lanqiao/eleventh/december_online/1`;

/**
 * 第十一届选拔赛线下-191020导出
 * @returns {Promise<any>}
 * @param slug
 */
export const eleventhOfflineUrl = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/lanqiao/eleventh/offline/1`;

/**
 * 第十一届选拔赛线下-191215导出
 * @returns {Promise<any>}
 * @param slug
 */
export const eleventhDecemberOfflineUrl = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/lanqiao/eleventh/december_offline/1`;

/**
 * 第十一届选拔赛线下SCW-20191215
 * @returns {Promise<any>}
 * @param slug
 */
export const eleventhDecemberCsUrl = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/lanqiao/eleventh/sc/1`;

/**
 * 第十一届选拔赛MC-191215导出
 * @returns {Promise<any>}
 * @param slug
 */
export const eleventhscratchMcUrl = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/lanqiao/eleventh/`;

/**
 * 商品列表
 * @returns {Promise<any>}
 * @param params
 */
export const commonProducts = params => http.get(`/common/products`, params);

/**
 * 寄件列表
 * @returns {Promise<any>}
 * @param params
 */
export const artpackExpress = params => http.get(`${OPERATE}/artpack-express`, params);

/**
 * 申请寄件
 * @returns {Promise<any>}
 * @param params
 */
export const artpackExpressPost = params => http.post(`${OPERATE}/artpack-express`, params);

/**
 * 录入寄件信息
 * @returns {Promise<any>}
 * @param params
 */
export const artpackExpressput = (id, params) =>
    http.put(`${OPERATE}/artpack-express/${id}`, params);

/**
 * 确认收件
 * @returns {Promise<any>}
 * @param params
 */
export const artpackExpressConfirm = (id, params) =>
    http.patch(`${OPERATE}/artpack-express/confirm/${id}`, params);

/**
 * 录入寄件信息
 * @returns {Promise<any>}
 * @param params
 */
export const artpackExpressCancel = (id, params) =>
    http.patch(`${OPERATE}/artpack-express/cancel/${id}`, params);

/**
 * 获取一个订单的寄件详情
 * @returns {Promise<any>}
 * @param params
 */
export const artpackExpressGet = (id, params) =>
    http.get(`${OPERATE}/artpack-express/${id}`, params);

/**
 * 2020冬令营报名表单
 * @returns {Promise<any>}
 * @param params
 */
export const winterCamp = params => http.get(`${OPERATE}/data/camp2020`, params);

/**
 * 2020冬令营报名表单
 * @returns {Promise<any>}
 * @param params
 */
export const campSetting = params => http.get(`${OPERATE}/data/camp2020_setting`, params);
/**
 * 冬夏令营视频推送列表
 * @returns {Promise<any>}
 */
export const campVideoList = params => http.get(`${OPERATE}/course_video`, params);
/**
 * 冬夏令营视频推送列表编辑或新增
 * @returns {Promise<any>}
 */
export const editVideoList = params => http.post(`${OPERATE}/course_video/store`, params);
/**
 * 冬夏令营视频推送观看详情
 * @returns {Promise<any>}
 */
export const campVideoListDetail = (id, params) =>
    http.get(`${OPERATE}/course_video/detail/${id}`, params);
/**
 * 冬夏令营视频推送 删除一条数据
 * @returns {Promise<any>}
 */
export const campVideoOneDelete = id => http.delete(`${OPERATE}/course_video/delete/${id}`);
/**
 * 冬夏令营视频推送 发布、取消发布一条数据
 * @returns {Promise<any>}
 */
export const campVideoOnePut = id => http.post(`${OPERATE}/course_video/release/${id}`);

/**
 * 2020冬令营选拔赛编辑
 * @returns {Promise<any>}
 * @param params
 */
export const editWinterCamp2020 = (id, params) =>
    http.put(`${OPERATE}/data/camp2020/${id}`, params);

/**
 * 学校引流页列表
 * @returns {Promise<any>}
 * @param params
 */
export const referralTraffic = (params) =>
    http.get(`${OPERATE}/referral_traffic`, params);

/**
 * 学校引流页详情
 * @returns {Promise<any>}
 * @param params
 */
export const referralTrafficDetail = (tag) =>
    http.get(`${OPERATE}/referral_traffic/${tag}`);

/**
 * 学校引流页创建人
 * @returns {Promise<any>}
 * @param params
 */
export const referralCeators = () =>
    http.get(`${OPERATE}/referral_traffic/creators`);

/**
 * 学校引流页新增/编辑
 * @returns {Promise<any>}
 * @param params
 */
export const referralStore = (params) =>
    http.post(`${OPERATE}/referral_traffic/store`, params);

/**
 * 学校引流页留资详情
 * @returns {Promise<any>}
 * @param params
 */
export const referraldataList = (params, tag) =>
    http.get(`${OPERATE}/referral_traffic/data_list/${tag}`, params);

/**
 * 学校引流页原始链接
 * @returns {Promise<any>}
 * @param params
 */
export const referralOrigins = () =>
    http.get(`${OPERATE}/referral_traffic/origins`);

/**
 * 班级列表
 */
export const getDualTeacherClasses = ({course, class_status, status, keyWords, page, per_page}) =>
    http.get(`/dou-teachers/classes`, {course, class_status, status, keyWords, page, per_page});
/**
 * 获取课程列表（大类，体系，课程）
 */
export const getDualTeacherCourses = () =>
    http.get(`/dou-teachers/courses`);
/**
 * 获取辅教老师 或者老师
 * @param {*} isTecher
 */
export const getDualTeachers = (isTecher) =>
    http.get(`/dou-teachers/teachers`, {is_teacher: isTecher});

/**
 * 新建班级
 * @param {*} content:{title:班级名称, course_id:课程Id, sn:班级编号,
 *   status:1-已发布 2-未发布,
 *   teacher_id:辅教老师id}
 */
export const postOnAddingDualClass = (content) =>
    http.post(`/dou-teachers/class/add`, content);
/**
 * 编辑班级
 * @param {*} classId 班级id
 * @param {*} content :{title: 班级名称， teacher_id: 辅教老师id，status: 发布状态}
 */
export const postOnDualEditClass = (classId, {title, teacher_id, status}) => {
    return http.post(`/dou-teachers/class/edit/${classId}`, {title, teacher_id, status})
}

/**
 * 新增课时
 * @param {*} classId 班级id
 * @param {*} content:{teacher_id: string 上课老师Id, time: string 上课时间, 
 *      frequency:上课频率'daily'|'weekly'|'monthly'|'twiceWeekly'，
 *      length: 时长, count: 创建次数, entrance: 课堂入口  }
 */
export const postOnNewDualLesson = (classId, content) => {
    return http.post(`/dou-teachers/lesson/store/${classId}`, content)
}

/**
 * 删除班级
 * @param {*} classId 班级id
 */
export const getOnDeleteDualClass = (classId) => {
    return http.get(`/dou-teachers/class/delete/${classId}`)
}

/**
 * 批量添加或移除机构
 * @param {*} classId 班级id
 * @param {*} param1 {type: 1-添加 2-删除, ins_ids: [] 机构id数组 }
 */
export const postOnBatchDeleteOrAddOrgan = (classId, {type, ins_ids}) => {
    return http.post(`/dou-teachers/class/ins/${classId}`, {type, ins_ids})
}

/**
 * 删除课时
 * @param {*} lessonId 课时Id
 */
export const getOnDeleteDualLesson = (lessonId) => {
    return http.get(`/dou-teachers/lesson/delete/${lessonId}`)
}

/**
 * 获取未在班级的机构
 * @param {*} classId 班级id
 */
export const getDualOrgansByClassId = (classId) =>
    http.get(`/dou-teachers/institutions/${classId}`)

/**
 *  添加或编辑评价
 * @param {*}  studentId 班级学生id
 * @param {*} content write_talk, 交流与写作
 *      innovation_change,  创新与变革
 *      technical_operation,  技术操作
 *      research_analysis, 研究与分析
 *      critical_think, 批判性思维
 *      digital_age, 数字化时代公民意识
 *      design_think, 设计思维
 *      knowledge, 学到的只是点
 *      teacher_evaluate, 老师的评价
 *      id 评价id 编辑时需要传id，新增时不需要
 */
export const postOnAddOrModifyDualComment = (studentId, {
    write_talk, innovation_change,
    technical_operation, research_analysis, critical_think,
    digital_age, design_think, knowledge, teacher_evaluate, evaluate_id, time_id
}) => {
    let postContent = {
        write_talk, innovation_change,
        technical_operation, research_analysis, critical_think,
        digital_age, design_think, knowledge, teacher_evaluate, time_id
    }
    if (evaluate_id) postContent.evaluate_id = evaluate_id;
    return http.post(`/dou-teachers/evaluate/add/${studentId}`, postContent)
}

/**
 * 课时管理
 * @param {*} content: course 数组, time 数组, status, keywords, page
 */
export const getDualLessons = ({course, time, status, keywords, page, per_page}) => {
    return http.get(`/dou-teachers/lessons`, {course, time, status, keywords, page, per_page})
}

/**
 * 班级日志
 * @param {*} classId 班级id
 */
export const getDualClassLogs = (classId, {page, per_page}) => {
    return http.get(`/dou-teachers/class-logs/${classId}`, {page, per_page})
}

/**
 * 课时详情
 * @param {*} lessonId 课时id
 */
export const getDualLessonDetail = (lessonId, {page, per_page}) => {
    return http.get(`/dou-teachers/lesson/detail/${lessonId}`, {page, per_page})
}

/**
 * 课时详情 选择题
 * @param {*} content:
 */
export const getDualLessonChoise = ({time_id, user_id, page, per_page}) => {
    return http.get(`/dou-teachers/lesson/choice`, {time_id, user_id, page, per_page})
}

/**
 * 课时详情 Oj题库
 * @param {*} params
 */
export const getDualLessonOJ = ({time_id, user_id, page, per_page}) => {
    return http.get(`/dou-teachers/lesson/noip`, {time_id, user_id, page, per_page})
}

/**
 * 课时详情 Oj题库-查看评测详情
 * @param {*} param0
 */
export const getDualLessonOJDetail = ({time_id, user_id}) => {
    return http.get(`/dou-teachers/lesson/noip/detail`, {time_id, user_id})
}

/**
 * 课时详情 选择题-查看评测详情
 * @param {*} param0
 */
export const getDualLessonChoiseDetail = ({id}) => {
    return http.get(`/dou-teachers/lesson/choice/detail`, {id})
}

/**
 * 批量关联课时
 * @param {*} lessons Array<{id: 课时id, entrance: 小鱼课堂入口, chapter_id: 章节Id}>
 */
export const postOnDualBatchRalativeLessons = (lessons) => {
    return http.post(`/dou-teachers/batch-lessons`, lessons)
}

/**
 * 编辑课时
 * @param {*} lessonId 课时id
 * @param {*} content: {teacher_id 上课老师id, time 上课时间, entrance 小鱼入口, chapter_id 所属章节}
 */
export const postOnDualEditLesson = (lessonId, {teacher_id, time, entrance, chapter_id}) => {
    return http.post(`/dou-teachers/lesson/edit/${lessonId}`, {teacher_id, time, entrance, chapter_id})
}

/**
 * 班级详情
 * @param {*} classId 班级id
 */
export const getDualClassDetail = (classId) => {
    return http.get(`/dou-teachers/class/detail/${classId}`)
}

/**
 * 获取抽奖数据
 * @param params
 */
export const getRaffles = (params) =>
    http.get(`${DATA}/raffles/1212`, params);

/**
 * 导出抽奖数据
 * @param params
 */
export const exportRaffles = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/raffles/1212/export`;

/**
 * 抽奖奖品列表
 */
export const getRafflePrizes = () =>
    http.get(`${DATA}/raffles/1212/prizes`);



/**
 * 作品评价
 * @param {*} params homework_id content
 */
export const workEvaluate = (params) => {
    return http.post(`/dou-teachers/lesson/homework/evaluate`,params)
}
/**
 * 2020冬令营报名人数统计表
 */
export const winterCampDataStatistic = () =>
    http.get(`${OPERATE}/data/camp2020/data-statistic/0`);

/**
 * 导出 2020冬令营报名人数统计表
 */
export const exportWinterCampDataStatistic = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/camp2020/data-statistic/1`;


/**
 * 导出2020冬令营报名表单
 * @returns {Promise<any>}
 * @param params
 */
export const exportWinterCamp = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${OPERATE}/data/camp2020/1`;

/**
 * 二次留资用户表
 * @returns {Promise<any>}
 * @param {*} params 
 */
export const getMultipleRegisterUsers = (params) => {
    return http.get(`${OPERATE}/multiple-reg-users`,params)
}
/**
 * 导出二次留资用户表
 * @returns {Promise<any>}
 * @param {*} params 
 */
export const exportMultipleRegisterUsers = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${OPERATE}/multiple-reg-users/1`;


/**
 * 录播课引流课报名表
 * @param params
 * @returns {Promise<any>}
 */
export const getRecordSignUp = params => http.get(`${DATA}/drainage/record`, params);


/**
 * 导出录播引流课报名表
 * @type {string}
 */
export const exportRecordSignUp = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/drainage/record/1`;

export const exportShareBonus = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/drainage/share-bonus/1`;
export const getShareBonus = params => http.get(`${DATA}/drainage/share-bonus`, params);

export const editShareBonus = (params, id) => {
    return http.put(`${DATA}/drainage/share-bonus/${id}`, params);
}
export const sendBonus = (params, id) => {
    return http.post(`${DATA}/drainage/send-bonus/${id}`, params);
}
/**
 * 艺术馆作品列表
 * @param params
 * @returns {Promise<any>}
 */
export const getCodingGalleryWorks = params => http.get(`${OPERATE}/coding_gallery_works`, params);

/**
 * 添加艺术馆作品
 * @param content: {work_id: 0, work_type: '', redirect: '', cover: ''}
 */
export const saveCodingGalleryWork = (params, id) => {
    if (id) {
        return http.put(`${OPERATE}/coding_gallery_works/${id}`, params);
    } else {
        return http.post(`${OPERATE}/coding_gallery_works`, params);
    }
};
export const unShelveCodingGalleryWork = (id) => {
    return http.patch(`${OPERATE}/coding_gallery_works/${id}`)
}
/**
 * 艺术馆 - 获取作品所属课程
 * @param {integer} id - 作品id
 */
export const getCodepkuWork = id => {
    return http.get(`/common/codepku-work/${id}`);
}

/**
 * 渠道合作数据统计
 * @param params
 */
export const getChannelCooperationData = params => {
    return http.get(`${DATA}/channel-cooperation`, params);
}

export const importMultipleRegisterUsers = (data) => {
    return http.post(`/operate/multiple/import`, data)
}

/**
 * 拼团活动列表（拼团领福利的拼团，不花钱）
 * @params 
 * @returns {Promise<any>}
 */
export const getGroupBuyingList = params => http.get(`${OPERATE}/pseudo-groupbuying`, params);

/**
 * 单个拼团活动详情
 * @params 
 * @returns {Promise<any>}
 */
export const getGroupBuying = id => http.get(`${OPERATE}/pseudo-groupbuying/${id}`);

/**
 * 新建拼团活动
 * @params
 * @returns {Promise<any>}
 */
export const createGroupBuying = data => http.post(`${OPERATE}/pseudo-groupbuying`, data);

/**
 * 修改拼团活动推广信息
 * @params
 * @returns {Promise<any>}
 */
export const updateGroupBuyingBasic = (id, data) => (
    http.put(`${OPERATE}/pseudo-groupbuying/${id}/basic`, data)
);

/**
 * 修改拼团活动推广页面
 * @params
 * @returns {Promise<any>}
 */
export const updateGroupBuyingPage = (id, data) => (
    http.put(`${OPERATE}/pseudo-groupbuying/${id}/page-info`, data)
);

/**
 * 启用/停用拼团活动
 * @params
 * @returns {Promise<any>}
 */
export const publishGroupBuying = id => (
    http.patch(`${OPERATE}/pseudo-groupbuying/${id}/is-publish`)
);

/**
 * 删除拼团活动
 * @params
 * @returns {Promise<any>}
 */
export const deleteGroupBuying = id => (
    http.delete(`${OPERATE}/pseudo-groupbuying/${id}`)
);

/**
 * 拼团活动创建人列表
 */
export const getGroupbuyingCreators = () => http.get(`${OPERATE}/pseudo-groupbuying/creators`);

/**
 * 拼团活动数据
 */
export const getGroupBuyingStats = (params) => http.get(`${OPERATE}/pseudo-groupbuying/groups`, params);

/**
 * 拼团活动列表-无分页
 */
export const getGroupBuyingListAll = () => http.get(`${OPERATE}/pseudo-groupbuying/basic-list`);

/**
 * 投诉建议表
 */
export const getComplainAdvices = params => http.get(`${OPERATE}/complain-advice`, params);

/**
 * 处理投诉建议
 */
export const dealComplainAdvices = (id) => http.put(`${OPERATE}/complain-advice/${id}`);

/**
 * 魔法馆数据
 */
export const getMagicHallData = params => http.get(`${OPERATE}/offline-store/magic-hall`, params);

/**
 * 导出魔法馆数据
 */
export const exportMagicHallData = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${OPERATE}/offline-store/magic-hall/1`;

/**
 * 加盟商会员-列表（表格数据）
 */
export const getB2bMembers = params => http.get(`/franchisee-members`, params);

/**
 * 加盟商会员-创建人列表
 */
export const getB2bMemberCreators = () => http.get(`/franchisee-members/creators`);

/**
 * 加盟商会员-课程列表
 */
export const getB2bMemberCourses = () => http.get(`/franchisee-members/courses`);

/**
 * 加盟商会员-客户负责人列表
 */
export const getB2bMemberOwners = () => http.get(`/franchisee-members/owners`);

/**
 * 加盟商会员-列表导出
 */
export const exportB2bMembersUrl = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/franchisee-members/export`;

/**
 * 加盟商会员-导入
 */
export const importB2bMembers = data => http.post(`/franchisee-members/import`, data);

/**
 * 加盟商会员-导入模板
 */
export const importB2bMembersTemplateUrl = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/franchisee-members/tpl`;

/**
 * 加盟商会员-获取导出【导入详细错误信息的excel】文件地址
 */
export const importErrorFileUrl = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/franchisee-members/failures`;

/**
 * 加盟商会员-编辑保存
 */
export const updateB2bMember = (id, data) => http.put(`/franchisee-members/${id}`, data);

/**
 * 获取2020noip集训营
 * @param {*} params 
 */
export const getOrsayNoip = params => http.get(`${DATA}/lanqiao/orsay-noip/`, params);

/**
 * 导出2020noip集训营数据
 */
export const exportOrsayNoipUrl = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${DATA}/lanqiao/orsay-noip/1`;
/**
 * 讯飞AI实验室用户表
 * @param {*} params
 */
export const getAiLaboratory = (params) => {
    return http.get(`/operate/changyan`, params)
}

/**
 * 导出讯飞AI实验室用户表
 * @type {string}
 */
export const exportAiLaboratory = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/operate/changyan?is_export=1`;

/**
 * 获取蓝桥杯赛事配置列表
 * @param {Object} params 
 */
export const getLanqiaoCompetitions = params => http.get(`${OPERATE}/lanqiao-competition`, params);

/**
 * 获取一场蓝桥杯赛事
 * @param {Number} id 
 */
export const getLanqiaoCompetition = id => http.get(`${OPERATE}/lanqiao-competition/${id}`);

/**
 * 获取一场赛事的报名列表
 * @param {Number} id 
 */
export const getLanqiaoCompRegs = (id, params) => http.get(`${OPERATE}/lanqiao-competition/${id}/registrations`, params);

/**
 * 导出一场赛事的报名列表
 * @param {Number} id 
 */
export const exportLanqiaoCompRegs = `//${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_HOST_SUFFIX}/${OPERATE}/lanqiao-competition`;

/**
 * 获取一条蓝桥杯赛事报名数据
 * @param {Number} id 
 */
export const getLanqiaoRegistration = id => http.get(`${OPERATE}/lanqiao-competition/registrations/${id}`);

/**
 * 更新一条蓝桥杯赛事报名数据
 * @param {Number} id
 * @param {FormData} data
 */
export const updateLanqiaoRegistration = (id, data) => http.put(`${OPERATE}/lanqiao-competition/registrations/${id}`, data);

/**
 * 创建蓝桥杯赛事
 * @param {Object} data 
 */
export const createLanqiaoCompetition = data => http.post(`${OPERATE}/lanqiao-competition`, data);

/**
 * 修改蓝桥杯赛事
 * @param {Number} id 
 * @param {Object} data 
 */
export const updateLanqiaoCompetition = (id, data) => http.put(`${OPERATE}/lanqiao-competition/${id}`, data);


/**
 * 删除蓝桥杯赛事
 * @param {Object} data 
 */
export const deleteLanqiaoCompetition = id => http.delete(`${OPERATE}/lanqiao-competition/${id}`);


/**
 * 获取蓝桥杯赛事配置（科目、类别）
 */
export const getLanqiaoConfigs = () => http.get(`${OPERATE}/lanqiao-competition/configs`);
