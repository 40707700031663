/**
 * 来源组件
 * Created by john
 * Date: 2019-03-28
 * Email: maiqingqiang@gmail.com
 * Time: 18:30
 */
import React from 'react';
import {Icon} from 'antd';
import XComponent from "~/components/Common/XComponent";

class WhetherIcon extends XComponent {

    static init = (value) => {

        if (value instanceof Object) {
            value = value();
        }

        if (/.*[\u4e00-\u9fa5]+.*/.test(value)) {
            value = value === '是';
        }

        return value ? <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{margin:'0 2px'}}/> :
            <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" style={{margin:'0 2px'}}/>;
    };

    render() {
        const {value} = this.props;
        return WhetherIcon.init(value);
    }
}

export default WhetherIcon;