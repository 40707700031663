/**
 * common
 * Created by john
 * Date: 2019-07-14
 * Email: maiqingqiang@gmail.com
 * Time: 12:07
 */
import {call, fork, put, select, throttle} from 'redux-saga/effects';
import {
    actions as accountOwnersActions,
    getAccountOwners,
    types as accountOwnersType
} from '../modules/entities/accountOwners';
import {actions as activityActions, getActivity, types as activityType,} from '../modules/entities/activity';
import {
    actions as labelWechatGroupActions,
    getLabelWechatGroup,
    types as labelWechatGroupType
} from '../modules/entities/labelWechatGroup';
import {actions as courseActions, getCourse, types as courseType} from '../modules/entities/course';
import {getAccountOwners as getAccountOwnersApi, getLabels} from "~/services/wechatManage";
import * as CommonService from "~/services/admin/common";
import {qrcodeCoursesList, getLanqiaoConfigs} from "~/services/admin/operate/data";
import isEmpty from 'lodash/isEmpty';
import {getModules} from "~/services/admin/operate/article";
import {
    actions as articleModuleActions,
    getArticleModule,
    types as articleModuleType
} from '../modules/entities/articleModule';
import { actions as lanqiaoConfigActions, getLanqiaoConfig, types as lanqiaoConfigType } from '../modules/entities/lanqiaoConfig';


/**
 * 获取微信管理员列表
 * @returns {IterableIterator<(() => CancelledEffect)|CallEffect|PutEffect<*|{type}>>}
 */
// eslint-disable-next-line
function* requestAccountOwners(action) {
    const state = yield select(state => getAccountOwners(state));
    if (!state.isCompleted || !state.payload) {
        yield accountOwners(action);
    } else {
        yield put(accountOwnersActions.complete());
    }
}

/**
 * @returns {IterableIterator<PutEffect<*|{payload, type}>|CallEffect|PutEffect<*>>}
 */
function* accountOwners(action) {
    try {
        const response = yield call(getAccountOwnersApi, action.params);
        if (response.code === 200) {
            yield put(accountOwnersActions.success(response.data));
        } else {
            yield put(accountOwnersActions.failure(response.message));
        }
    } catch (e) {
        yield put(accountOwnersActions.failure(e));
    }
}

/**
 * 获取群组标签
 * @returns {IterableIterator<(() => CancelledEffect)|CallEffect|PutEffect<*|{type}>>}
 */
function* workerLabelWechatGroup(action) {
    const state = yield select(state => getLabelWechatGroup(state));
    if (!state.isCompleted || !state.payload) {
        yield labelWechatGroup(action);
    } else {
        yield put(labelWechatGroupActions.complete());
    }
}

/**
 * @returns {IterableIterator<PutEffect<*|{payload, type}>|CallEffect|PutEffect<*>>}
 */
function* labelWechatGroup(action) {
    try {
        const response = yield call(getLabels, {category: 1, ...action.params});
        if (response.code === 200) {
            yield put(labelWechatGroupActions.success(response.data));
        } else {
            yield put(labelWechatGroupActions.failure(response.message));
        }
    } catch (e) {
        yield put(labelWechatGroupActions.failure(e));
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<IterableIterator<PutEffect<*|{payload, type}>|CallEffect|PutEffect<*>>|SelectEffect>}
 */
function* workerActivity(action) {
    let state = yield select(state => getActivity(state));
    if (!state.isCompleted || !state.payload) {
        yield activity(action, state);
    } else {
        yield put(activityActions.complete());
    }
}

/**
 *
 * @param state
 * @param action
 * @param selectedOptions
 * @returns {IterableIterator<PutEffect<*|{payload, type}>|CallEffect|PutEffect<*>>}
 */
function* activity(action, state) {
    try {
        state = state || (yield select(state => getActivity(state)));
        const response = yield call(CommonService.getActivity, action.params);
        if (response.code === 200) {

            let data = response.data;
            let options;
            if (action.type === activityType.REQUEST) {
                options = data.map((item) => {
                    if (!isEmpty(item.children)) {
                        return {...item, isLeaf: false}
                    }
                    return item;
                });

                options = [...state.payload, ...options];

                yield put(activityActions.success(options));
            } else if (action.type === activityType.UPDATE) {

                const targetOption = action.targetOption;
                targetOption.next = data.map((item) => {
                    if (!isEmpty(item.children)) {
                        return {...item, isLeaf: false}
                    }
                    return item;
                });

                targetOption.loading = false;

                yield put(activityActions.success(state.payload));
            }


        } else {
            yield put(activityActions.failure(response.message));
        }
    } catch (e) {
        yield put(activityActions.failure(e));
    }
}

function* workerCourse() {
    const state = yield select(state => getCourse(state));
    console.log(state)
    if (!state.isCompleted || !state.payload) {
        yield course();
    } else {
        yield put(courseActions.complete());
    }
}

function* course() {
    try {
        const response = yield call(qrcodeCoursesList);
        if (response.code === 200) {
            let data = response.data;
            yield put(courseActions.success(data));
        } else {
            yield put(courseActions.failure(response.message));
        }
    } catch (e) {
        yield put(courseActions.failure(e));
    }
}

function* workerArticleModule() {
    const state = yield select(state => getArticleModule(state));

    if (!state.isCompleted || !state.payload) {
        yield articleModule();
    } else {
        yield put(articleModuleActions.complete());
    }
}

function* articleModule() {
    try {
        const response = yield call(getModules, {all: true});
        if (response.code === 200) {
            let data = response.data;
            yield put(articleModuleActions.success(data));
        } else {
            yield put(articleModuleActions.failure(response.message));
        }
    } catch (e) {
        yield put(articleModuleActions.failure(e));
    }
}

function* workerLanqiaoConfig() {
    const state = yield select(state => getLanqiaoConfig(state));
    console.log(state)
    if (!state.isCompleted || !state.payload) {
        yield lanqiaoConfig();
    } else {
        yield put(lanqiaoConfigActions.complete());
    }
}

function* lanqiaoConfig() {
    try {
        const response = yield call(getLanqiaoConfigs);
        if (response.code === 200) {
            let data = response.data;
            yield put(lanqiaoConfigActions.success(data));
        } else {
            yield put(lanqiaoConfigActions.failure(response.message));
        }
    } catch (e) {
        yield put(lanqiaoConfigActions.failure(e));
    }
}

function* watcher() {
    yield throttle(500, accountOwnersType.REQUEST, accountOwners);
    yield throttle(500, accountOwnersType.UPDATE, accountOwners);
    yield throttle(500, labelWechatGroupType.REQUEST, workerLabelWechatGroup);
    yield throttle(500, labelWechatGroupType.UPDATE, labelWechatGroup);
    yield throttle(500, activityType.REQUEST, workerActivity);
    yield throttle(500, activityType.UPDATE, activity);
    yield throttle(500, courseType.REQUEST, workerCourse);
    yield throttle(500, courseType.UPDATE, course);
    yield throttle(500, articleModuleType.REQUEST, workerArticleModule);
    yield throttle(500, articleModuleType.UPDATE, articleModule);
    yield throttle(500, lanqiaoConfigType.REQUEST, workerLanqiaoConfig);
    yield throttle(500, lanqiaoConfigType.UPDATE, lanqiaoConfig);
}

export default fork(watcher)