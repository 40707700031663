/**
 * articleModule
 * Created by john
 * Date: 2019-07-23
 * Email: maiqingqiang@gmail.com
 * Time: 10:36
 */
import entityFactory from './entityFactory';
import {bindActionCreators} from "redux";

const namespace = 'article-module';

export const {types, actions, reducer} = entityFactory(namespace, {payload: []}, {}, {
    [`${namespace}/SUCCESS`]: (state, action) => {
        return {
            ...state,
            isFetching: false,
            isCompleted: true,
            payload: action.payload
        }
    }
});

export default reducer;

export const getArticleModule = state => state.entities.articleModule;

export const getArticleModuleActions = dispatch => bindActionCreators(actions, dispatch);