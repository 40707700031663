/**
 * entityFactory
 * Created by john
 * Date: 2019-07-13
 * Email: maiqingqiang@gmail.com
 * Time: 22:19

 * @param namespace
 * @param otherInitialState
 * @param otherTypes
 * @param otherHandles
 * @param otherActions
 * @returns {{handles: {}, types: ({}&{SUCCESS: string, REQUEST: string, FAILURE: string}), reducer: (function(*=, *=): (*|({}&{isFetching: boolean, error: null, isFailure: boolean, isCompleted: boolean}))), actions: ({}&{request: (function(): {type: string}), success: (function(*): {payload: *, type: string}), failure: (function(*): {payload: *, type: string})})}}
 */
export default (namespace, otherInitialState = {}, otherTypes = {}, otherHandles = {}, otherActions = {}) => {

    let initialState = {
        isFetching: false,
        isCompleted: false,
        isFailure: false,
        error: null,
        payload: [],
        ...otherInitialState
    };

    const types = {
        REQUEST: `${namespace}/REQUEST`,
        UPDATE: `${namespace}/UPDATE`,
        SUCCESS: `${namespace}/SUCCESS`,
        COMPLETE: `${namespace}/COMPLETE`,
        FAILURE: `${namespace}/FAILURE`,
        ...otherTypes
    };


    const handles = {
        [types.REQUEST]: (state) => {
            return {
                ...state,
                isFetching: true
            }
        },
        [types.UPDATE]: (state) => {
            return {
                ...state,
                isFetching: true
            }
        },
        [types.SUCCESS]: (state, action) => {
            return {
                ...state,
                isFetching: false,
                isCompleted: true,
                payload: action.payload
            }
        }, [types.COMPLETE]: (state) => {
            return {
                ...state,
                isFetching: false,
                isCompleted: true,
            }
        },
        [types.FAILURE]: (state, action) => {
            return {
                ...state,
                isFetching: false,
                isFailure: true,
                error: action.payload
            }
        },
        ...otherHandles
    };


    const actions = {
        request: (params = {}) => ({
            type: types.REQUEST,
            params
        }),
        update: (params = {}) => ({
            type: types.UPDATE,
            params
        }),
        success: (payload) => ({
            type: types.SUCCESS,
            payload
        }),
        complete: () => ({
            type: types.COMPLETE
        }),
        failure: (payload) => ({
            type: types.FAILURE,
            payload
        }),
        ...otherActions
    };

    const reducer = (state = initialState, action) => (handles[action.type] && handles[action.type](state, action)) || state;

    return {
        types,
        handles,
        actions,
        reducer
    }
}