/**
 * Title: Authorized
 * Author: John Mai
 * Email: maiqingqiang@gmail.com
 * Date: 2019-08-13 17:42:01
 */
import React, {FunctionComponent, memo, ReactNode} from 'react';
import isEmpty from 'lodash/isEmpty';
import intersection from "lodash/intersection";
import {useSelector} from "react-redux";
import {getUser} from "~/store/modules/entities/user";

// 需要检测是否当前登录用户管理的数据
const needCheckIsMePermissions = [
    'op_view_wechat_group',
    'op_import_wechat_group',
    'op_import_wechat_user'
];

const Authorized: FunctionComponent<{
    permissions: [],
    children: ReactNode,
    noPermissions: [],
    args: { id: number }
}> = ({
          permissions,
          children,
          noPermissions,
          args
      }) => {

    const user = useSelector(getUser);

    /**
     * 检测是否跟当前用户
     * @returns {boolean}
     */
    const checkIsMe = () => {
        return !(!isEmpty(args) && args.id !== user.id);
    };

    if ((!(window as any).codepku.checkPermission(permissions, user)) ||
        (!isEmpty(intersection(needCheckIsMePermissions, user.permissions)) && !checkIsMe()) ||
        (window as any).codepku.checkNoPermissions(noPermissions, user)) {
        return null;
    }

    return (<>{children}</>);
};

export default memo(Authorized);