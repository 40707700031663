import React, {FunctionComponent, memo} from 'react';
import styles from './style.module.scss';
import loading from './loading.gif';

const XLoader: FunctionComponent<{ tip: string }> = ({tip}) => {
    return <div className={styles["x-loader"]}>
        <img className={styles["x-loader-image"]} src={loading} alt="loading"/>
        <div className={styles['x-loader-tip']}>{tip}</div>
    </div>;
};

export default memo(XLoader);
