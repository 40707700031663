/**
 * index
 * Created by john
 * Date: 2019-07-13
 * Email: maiqingqiang@gmail.com
 * Time: 15:41
 */
import {all} from 'redux-saga/effects'
import auth from './auth';
import common from './common';
import fetch from './fetch';
import logger from './logger';

export default function* rootSaga() {
    yield all([
        auth,
        common,
        fetch,
        logger
    ])
}