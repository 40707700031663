/**
 * api
 * Created by john
 * Date: 2019-01-26
 * Email: maiqingqiang@gmail.com
 * Time: 09:54
 */


export const HOST = (process.env.REACT_APP_PUBLIC_CLASS_API_HTTPS === 'true' ? 'https' : 'http')
    + '://' + process.env.REACT_APP_PUBLIC_CLASS_API_HOST + (process.env.REACT_APP_PUBLIC_CLASS_API_HOST_SUFFIX || '');

export const STUDY = (process.env.REACT_APP_STUDYAPI_API_HTTPS === 'true' ? 'https' : 'http')
    + '://' + process.env.REACT_APP_STUDYAPI_API_HOST;

export const COURSE = (process.env.REACT_APP_CODEPKU_API_HTTPS === 'true' ? 'https' : 'http')
    + '://' + process.env.REACT_APP_CODEPKU_API_HOST + (process.env.REACT_APP_CODEPKU_API_HOST_SUFFIX || '');

export const TRAINAPI = (process.env.REACT_APP_TRAINAPI_API_HTTPS === 'true' ? 'https' : 'http')
    + '://' + process.env.REACT_APP_TRAINAPI_API_HOST + (process.env.REACT_APP_TRAINAPI_API_HOST_SUFFIX || '');