import React from 'react';
import {Avatar, Dropdown, Icon, Layout, Menu} from 'antd';
import PropTypes from 'prop-types';
import {NavLink, withRouter} from 'react-router-dom';
import logo from '~/assets/logo4.png';
import {connect} from "react-redux";
import {Authorized} from "~/components/index";
import {getUser} from "~/store/modules/entities/user";
import {getCommon} from "~/store/modules/common";
import XComponent from "~/components/Common/XComponent";
import styles from './style.module.scss';
import avatar from '~/assets/avatar.png';

const mapStateToProps = (state) => {
    return {
        common: getCommon(state),
        user: getUser(state)
    };
};

@withRouter
@connect(mapStateToProps)
class Header extends XComponent {

    static defaultProps = {
        navs: [],
    };

    render() {

        const _this = this;

        const {location, navs, user} = _this.props;

        let avatarProps = sessionStorage.getItem('avatarProps');

        if (avatarProps) {
            avatarProps = JSON.parse(avatarProps);
        } else {
            const existsImage = (url) => {
                if (url) {
                    let image = new Image();
                    image.src = url;
                    return (image.fileSize > 0 || (image.width > 0 && image.height > 0));
                }
                return false;
            };

            let userImage = '';
            if (user.avatar) {
                userImage = user.avatar.indexOf('http') !== -1 ? user.avatar : (process.env.REACT_APP_SSO_HTTPS === 'true' ? 'https' : 'http')
                    + '://' + process.env.REACT_APP_SSO_HOST + '/' + user.avatar;
            }

            existsImage(userImage) || (userImage = avatar);

            avatarProps = {src: userImage};

            sessionStorage.setItem('avatarProps', JSON.stringify({src: userImage}));
        }

        return (
            <Layout.Header className={styles['x-header']}>
                 <a href={process.env.REACT_APP_SSO_HOST !== undefined ?  (process.env.REACT_APP_API_HTTPS === 'true' ? 'https' : 'http') + "://" 
                    + process.env.REACT_APP_SSO_HOST : 'https://www.codepku.com'} className={styles['x-logo']}>
                    <img src={logo} width={140} alt='编玩边学-运营中心'/>
                </a>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    selectedKeys={[location.pathname.match(/^(\/\w+){2}/)[0]]}
                    style={{lineHeight: '64px'}}
                >
                    {navs.map(item => {
                        if (item.notRender) return null;
                        if (window.codepku.checkNoPermissions(item.noPermissions, user)) return null;
                        return (
                            <Menu.Item key={item.path}>
                                <NavLink to={item.path}>{item.title}</NavLink>
                            </Menu.Item>
                        )
                    })}
                </Menu>
                <Dropdown className={styles['ant-dropdown']} overlay={
                    <Menu>
                        <Menu.Item>
                            <Authorized noPermissions={['saige_users']}>
                                <Icon type="home"/>
                                <span>
                                    <a target="_blank" rel="noopener noreferrer"
                                       href={process.env.REACT_APP_PLATFORM_ADMIN || 'https://www.codepku.com/admin'}>官网后台</a>
                                </span>
                            </Authorized>
                        </Menu.Item>
                        <Menu.Divider/>
                        <Menu.Item>
                            <Icon type="logout"/><span>退出</span>
                        </Menu.Item>
                    </Menu>
                }>
                    <div className={styles['x-user']}>
                        <Avatar className={styles['x-avatar']} size="large" {...avatarProps}/>
                        <span className={styles['x-username']}>{user.name}</span>
                    </div>
                </Dropdown>
            </Layout.Header>
        );
    }

}

Header.propTypes = {
    navs: PropTypes.array
};
export default Header;
