/**
 * XConsole
 * Created by john
 * Date: 2019-03-23
 * Email: maiqingqiang@gmail.com
 * Time: 02:03
 */
import React, {FunctionComponent, memo} from 'react';
import {Icon, message, Tag} from 'antd';
import {TagProps} from 'antd/lib/tag';
import copy from "copy-to-clipboard";

const XUrl: FunctionComponent<TagProps & { url: string }> = ({url, ...props}) => {

    const onClick = () => {
        copy(url);
        message.success('复制链接成功')
    };

    return <Tag color="blue" onClick={onClick} {...props}>{url}&nbsp;&nbsp;<Icon type="copy"/></Tag>
};

export default memo(XUrl);
