/**
 * common
 * Created by john
 * Date: 2019-03-28
 * Email: maiqingqiang@gmail.com
 * Time: 18:38
 */
import http from '~/utils/http';
import {COMMON} from '~/configs/api';

/**
 * 获取来源列表
 * @param parentId
 * @returns {Promise<any>}
 */
export const getActivity = (parentId = 0) => http.get(`${COMMON}/activity/${parentId}`);

export const getCosTempKeys = () => http.get(`${COMMON}/get-cos-tempkeys`);

