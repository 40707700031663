import Axios from 'axios';
import {message} from 'antd';
import _ from 'lodash';

let timeout = process.env.REACT_APP_HTTP_TIMEOUT || null;

if (timeout) Axios.defaults.timeout = timeout;

Axios.defaults.withCredentials = true;

Axios.defaults.baseURL =
    (process.env.REACT_APP_API_HTTPS === 'true' ? 'https' : 'http') +
    '://' +
    process.env.REACT_APP_API_HOST +
    (process.env.REACT_APP_API_HOST_SUFFIX || '');

// const CancelToken = Axios.CancelToken;
export let sources = {};
// let requestList = [];

// 请求拦截器
Axios.interceptors.request.use(
    config => {
        // const request = `${JSON.stringify(config.url)}${
        //     config.data ? JSON.stringify(config.data) : ''
        // }`;
        // config.cancelToken = new CancelToken(cancel => {
        //     sources[request] = cancel;
        // });
        // if (requestList.includes(request)) {
        //     console.log(request);
        //     sources[request]('取消重复请求');
        // } else {
        //     requestList.push(request);
        // }

        return config;
    },
    err => {
        // try {
        //     window.apm.captureError(new Error(err))
        // } catch (e) {
        //
        // }

        return Promise.reject(err);
    }
);

// 响应拦截器
Axios.interceptors.response.use(
    response => {
        // const request = JSON.stringify(response.config.url) + JSON.stringify(response.config.data);
        // requestList.splice(requestList.findIndex(item => item === request), 1);

        if (!response.data.code) response.data.code = response.status;

        response.data.isSuccess = () => response.data.code >= 200 && response.data.code < 300;

        if (!response.data.isSuccess()) {
            let msg = 'unknown error';
            if (response.data && response.data.errors) {
                msg = '错误详情：';
                const errors = response.data.errors;
                Object.keys(errors).forEach(value => {
                    msg += errors[value].join(' | ');
                });
            } else {
                msg = response.data
                    ? response.data.message || response.statusText
                    : response.statusText;
            }
            message.error(msg);
            return Promise.reject(response);
        }

        return response;
    },
    err => {
        // if (Axios.isCancel(err)) {
        //     requestList.length = 0;
        // } else {
        // try {
        //     window.apm.captureError(new Error(err))
        // } catch (e) {
        //
        // }

        if (err.response) {
            switch (err.response.status) {
                case 401:
                    message.error('请登录！');
                    window.codepku.redirectSSO();
                    break;
                case 403:
                    message.error('抱歉，你无权操作！');
                    break;
                case 404:
                    message.error('请求不存在！');
                    break;
                default:
                    let msg = 'unknown error';
                    if (err.response.data && err.response.data.errors) {
                        msg = '错误详情：';
                        const errors = err.response.data.errors;
                        Object.keys(errors).forEach(value => {
                            msg += errors[value].join(' | ');
                        });
                    } else {
                        msg = err.response.data
                            ? err.response.data.message || err.response.statusText
                            : err.response.statusText;
                    }
                    message.error(msg);
            }
        } else {
            if (err.message.includes('timeout')) {
                message.error('请求超时！请检查网络是否正常');
            } else {
                message.error('请求失败，请检查网络是否已连接');
            }
        }
        return Promise.reject(err);
    }
);

/**
 * GET Http
 * @param url               请求地址
 * @param params            请求参数
 * @param headers           请求头
 * @returns {Promise<any>}
 */
const get = (url, params, headers = {}) => {
    return new Promise((resolve, reject) => {
        Axios.get(url, {
            params: params,
            ...headers
        })
            .then(response => {
                resolve(response.data, response);
            })
            .catch(err => {
                reject(err);
            });
    });
};

/**
 * POST Http
 * @param url               请求地址
 * @param data              请求参数
 * @param headers           请求头
 * @returns {Promise<any>}
 */
const post = (url, data, headers = {}) => {
    return new Promise((resolve, reject) => {
        Axios.post(url, data, headers)
            .then(response => {
                resolve(response.data, response);
            })
            .catch(err => {
                reject(err);
            });
    });
};

/**
 * PUT Http
 * @param url
 * @param data
 * @param headers
 * @returns {Promise<any>}
 */
const put = (url, data, headers = {}) => {
    return new Promise((resolve, reject) => {
        Axios.put(url, data, headers)
            .then(response => {
                resolve(response.data, response);
            })
            .catch(err => {
                reject(err);
            });
    });
};

/**
 * PUT Http
 * @param url
 * @param data
 * @param headers
 * @returns {Promise<any>}
 */
const patch = (url, data, headers = {}) => {
    return new Promise((resolve, reject) => {
        Axios.patch(url, data, headers)
            .then(response => {
                resolve(response.data, response);
            })
            .catch(err => {
                reject(err);
            });
    });
};

/**
 * DEL Http
 * @param url
 * @param params
 * @param headers
 * @returns {Promise<any>}
 */
const del = (url, params, headers = {}) => {
    return new Promise((resolve, reject) => {
        Axios.delete(url, {
            params: params,
            ...headers
        })
            .then(response => {
                resolve(response.data, response);
            })
            .catch(err => {
                reject(err);
            });
    });
};

/**
 * 上传文件
 * @param url
 * @param data
 * @param headers
 * @returns {Promise<any>}
 */
const upload = (url, data, headers = {}) => {
    headers = {...headers, 'Content-Type': 'multipart/form-data'};

    let form = new FormData();
    _.forEach(data, function (value, key) {
        form.append(key, value);
    });

    return new Promise((resolve, reject) => {
        Axios.post(url, form, headers)
            .then(response => {
                resolve(response.data, response);
            })
            .catch(err => {
                reject(err);
            });
    });
};

export default {
    get,
    post,
    put,
    delete: del,
    upload,
    patch
};
