/**
 * 课程组件
 * Created by john
 * Date: 2019-06-28
 * Email: maiqingqiang@gmail.com
 * Time: 18:30
 */
import React, {FunctionComponent, useEffect} from 'react';
import {Cascader} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {getCourse, getCourseActions} from '~/store/modules/entities/course';

const CourseCascader: FunctionComponent = (props) => {

    const course: any = useSelector(getCourse);
    const courseActions = getCourseActions(useDispatch());

    useEffect(() => {
        courseActions.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Cascader
        placeholder='课程选择'
        options={course.payload}
        fieldNames={{label: 'title', value: 'id', children: 'children'}}
        changeOnSelect
        {...props}
    />
};

export default CourseCascader;