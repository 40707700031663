import React from 'react';

export default {
    path: '/admin/operation',
    title: '运营',
    notRender: false,
    children: [
        {
            path: '/admin/operation',
            title: '主页',
            icon: 'home',
            exact: true,
            component: React.lazy(() => import('~/pages/Admin/Operation/Home'))
        },
        {
            path: '/admin/test',
            title: 'TEST',
            notMenu: true,
            component: React.lazy(() => import('~/pages/Admin/Operation/Test/index2.tsx'))
        },
        {
            path: '/admin/operation/open',
            title: '直播间',
            icon: 'video-camera',
            permissions: ['public_class_list', 'public_class_super', 'train_list', 'train_super', 'op_myself_train_list'],
            children: [
                {
                    path: '/admin/operation/open/direct_seeding',
                    title: '公开课直播间',
                    permissions: ['public_class_list', 'public_class_super'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/Open/DirectSeeding')
                    )
                },
                {
                    path: '/admin/operation/open/teachers',
                    title: '师训直播间',
                    permissions: ['train_list', 'train_super', 'op_myself_train_list'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Open/Teachers'))
                }
            ]
        },
        {
            path: '/admin/operation/data',
            title: '数据管理',
            icon: 'database',
            permissions: [
                'mc_world_data',
                'saige',
                'saige_users',
                'op_view_five_anniversary',
                'op_view_referral_punch',
                'op_view_lanqiao_national',
                'op_view_minicode',
                'op_view_summer_school',
                'op_view_children_day_2019',
                'op_view_lanqiao_training',
                '2018drainage_course_data',
                'drainage_order_add',
                'op_view_lanqiao_floor',
                'op_view_spo_floor',
                'op_view_spo_apply',
                'op_view_green_stem',
                'op_view_real_name_records',
                'op_view_eleven_lanqiao',
                'op_view_artpack',
                'op_view_winter_camp_sign',
                'op_view_winter_camp_unsign',
                'op_view_winter_camp_video',
                'op_view_raffles',
                'op_view_winter_camp_data',
                'view_multiple_register_users',
                'view_multiple_register_users_hidden_mobile',
                'channel_cooperation_data',
                'view_pseudo_group_buying_users',
                'view_magic_member_data',
                'view_all_franchisee_members'
            ],
            children: [
                {
                    path: '/admin/operation/data/multiple-reg-users',
                    title: '二次留资用户',
                    permissions: ['view_multiple_register_users', 'view_multiple_register_users_hidden_mobile'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/Data/MultipleRegisterUsers')
                    )
                },
                {
                    path: '/admin/operation/data/winter-camp',
                    title: '冬令营数据',
                    permissions: [
                        'op_view_winter_camp_sign',
                        'op_view_winter_camp_data',
                        'op_view_winter_camp_unsign',
                        'op_view_winter_camp_video'
                    ],
                    children: [
                        {
                            path: '/admin/operation/data/winter-camp/winter-camp-list/1',
                            title: '2020冬令营报名表单(已报名)',
                            permissions: ['op_view_winter_camp_sign'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/WinterCamp/WinterCampList')
                            )
                        },
                        {
                            path: '/admin/operation/data/winter-camp/winter-camp-data/0',
                            title: '2020冬令营报名人数统计表',
                            permissions: ['op_view_winter_camp_data'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/WinterCamp/WinterCampDataStatistic')
                            )
                        },
                        {
                            path: '/admin/operation/data/winter-camp/winter-camp-list/0',
                            title: '2020冬令营报名表单(未报名)',
                            permissions: ['op_view_winter_camp_unsign'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/WinterCamp/WinterCampList')
                            )
                        },
                        {
                            path: '/admin/operation/data/video-list',
                            title: '冬令营视频推送',
                            permissions: ['op_view_winter_camp_video'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/WinterSummerCamp/VideoList')
                            )
                        }
                    ]
                },
                {
                    path: `/admin/operation/data/video-list-detail/:id`,
                    title: '查看详情',
                    permissions: [],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/Data/WinterSummerCamp/DataDetail')
                    ),
                    notMenu: true,
                },
                {
                    path: '/admin/operation/data/mc-world',
                    title: 'MC课程体验活动',
                    permissions: ['mc_world_data'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Data/McWorld'))
                },
                {
                    path: '/admin/operation/data/saige',
                    title: '赛格数据',
                    permissions: ['saige', 'saige_users'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Data/Saige'))
                },
                {
                    path: '/admin/operation/data/five-anniversary',
                    title: '周年庆留资',
                    permissions: ['op_view_five_anniversary'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/Data/FiveAnniversary')
                    )
                },
                {
                    path: '/admin/operation/data/referral-punch',
                    title: '转介绍打卡',
                    permissions: ['op_view_referral_punch'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/Data/Referral/activity-punch')
                    )
                },
                // {
                //     path: '/admin/operation/data/ap-mp5',
                //     title: 'admp5夏校数据',
                //     permissions: ['op_view_admp5'],
                //     component: React.lazy(() => import('~/pages/Admin/Operation/Data/AdMp5'))
                // },
                {
                    path: '/admin/operation/data/match',
                    title: '蓝桥杯比赛',
                    // icon: 'table',
                    permissions: [
                        'op_view_lanqiao_national',
                        'op_view_minicode',
                        'op_view_eleven_lanqiao'
                    ],
                    children: [
                        {
                            path: '/admin/operation/data/match/eleven-national',
                            title: '第十一届选拔赛报名表',
                            permissions: ['op_view_lanqiao_national'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/ElevenNational')
                            )
                        },
                        {
                            path: '/admin/operation/data/match/mini-code',
                            title: '第十一届迷你选拔赛报名表',
                            permissions: ['op_view_minicode'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/MiniCode')
                            )
                        },
                        {
                            path: '/admin/operation/data/match/national',
                            title: '第十届国赛报名表',
                            permissions: ['op_view_lanqiao_national'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/National')
                            )
                        },
                        {
                            path: '/admin/operation/data/match/on-line/on-line-list',
                            title: '第十一届选拔赛线上-191020',
                            permissions: ['op_view_eleven_lanqiao'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/OnLine/OnLineList')
                            )
                        },
                        {
                            path: '/admin/operation/data/match/under-the-line/lower-list',
                            title: '第十一届选拔赛线下-191020',
                            permissions: ['op_view_eleven_lanqiao'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/UnderTheLine/LowerList')
                            )
                        },
                        {
                            path: '/admin/operation/data/match/on-line/on-line-list-fifteen',
                            title: '第十一届选拔赛线上-191215',
                            permissions: ['op_view_eleven_lanqiao'],
                            component: React.lazy(() =>
                                import(
                                    '~/pages/Admin/Operation/Data/Match/OnLine/OnLineListFifteen'
                                )
                            )
                        },
                        {
                            path: '/admin/operation/data/match/under-the-line/lower-list-fifteen',
                            title: '第十一届选拔赛线下-191215',
                            permissions: ['op_view_eleven_lanqiao'],
                            component: React.lazy(() =>
                                import(
                                    '~/pages/Admin/Operation/Data/Match/UnderTheLine/LowerListFifteen'
                                )
                            )
                        },
                        {
                            path: '/admin/operation/data/match/under-cs/lowerList-cs',
                            title: '第十一届选拔赛SCW-191215',
                            permissions: ['op_view_eleven_lanqiao'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/underCs/LowerListCs')
                            )
                        },
                        {
                            path: '/admin/operation/data/match/mc-selection/mc-list/scratch_mc',
                            title: '第十一届选拔赛MC-191215',
                            permissions: ['op_view_eleven_lanqiao'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/McSelection/McList')
                            )
                        },
                        {
                            path: '/admin/operation/data/match/mc-selection/mc-list/seven',
                            title: '第十一届选拔赛线下-200207',
                            permissions: ['op_view_eleven_lanqiao'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/McSelection/McList')
                            )
                        },
                        {
                            path: '/admin/operation/data/match/mc-selection/mc-list/fourteen',
                            title: '第十一届选拔赛线下-200214',
                            permissions: ['op_view_eleven_lanqiao'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/McSelection/McList')
                            )
                        },

                        {
                            path: '/admin/operation/data/match/mc-selection/mc-list/province',
                            title: '第十一届蓝桥杯省赛',
                            permissions: ['op_view_eleven_lanqiao'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/McSelection/McList')
                            )
                        },
                        {
                            path: '/admin/operation/data/match/mc-selection/mc-list/teenager',
                            title: '蓝桥杯模拟比赛',
                            permissions: ['op_view_eleven_lanqiao'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/McSelection/McList')
                            )
                        },
                        {
                            path: '/admin/operation/data/match/mc-selection/mc-list/stema',
                            title: '蓝桥杯Stema模拟比赛',
                            permissions: ['op_view_eleven_lanqiao'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/McSelection/McList')
                            )
                        },
                        {
                            path: '/admin/operation/data/match/mc-selection/mc-list/0530',
                            title: '第十一届选拔赛线上-20200530',
                            permissions: ['op_view_eleven_lanqiao'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/McSelection/McList')
                            )
                        },
                        {
                            path: '/admin/operation/data/match/mc-selection/mc-list/stema_0530',
                            title: '第十一届Stema选拔赛线上-20200530',
                            permissions: ['op_view_eleven_lanqiao'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/McSelection/McList')
                            )
                        },
                        {
                            path: '/admin/operation/data/match/mc-selection/mc-list/province_0620',
                            title: '第十一届蓝桥杯省赛-0620',
                            permissions: ['op_view_eleven_lanqiao'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Match/McSelection/McList')
                            )
                        }
                    ]
                },
                {
                    path: '/admin/operation/data/lanqiao',
                    title: '蓝桥杯集训营',
                    // icon: 'table',
                    permissions: [
                        'op_view_lanqiao_national',
                        'op_view_lanqiao_floor',
                        'op_view_lanqiao_training'
                    ],
                    children: [
                        {
                            path: '/admin/operation/data/lanqiao/landing',
                            title: '第十一届选拔赛落地页',
                            permissions: ['op_view_lanqiao_floor'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/LanQiao/Landing')
                            )
                        },
                        {
                            path: '/admin/operation/data/lanqiao/camp',
                            title: '第十一届选拔赛报名表',
                            permissions: ['op_view_lanqiao_training'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/LanQiao/Camp')
                            )
                        },
                        {
                            path: '/admin/operation/data/training',
                            title: '集训营报名数据',
                            permissions: ['op_view_lanqiao_national'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/LanQiao/ProvincialGames')
                            )
                        },
                        {
                            path: '/admin/operation/data/lanqiao/noip-training',
                            title: '2020NOIP集训营报名表',
                            permissions: ['op_view_lanqiao_training'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/LanQiao/NoipTraining')
                            )
                        },
                    ]
                },
                {
                    path: '/admin/operation/data/summer-school',
                    title: '夏校报名',
                    permissions: ['op_view_summer_school'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Data/SummerSchool'))
                },
                {
                    path: '/admin/operation/data/drainage',
                    title: '引流课',
                    permissions: ['2018drainage_course_data', 'drainage_order_add'],
                    children: [
                        {
                            path: '/admin/operation/data/drainage/sign-up',
                            title: '报名表',
                            permissions: ['2018drainage_course_data'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Drainage/SignUp')
                            )
                        },
                        {
                            path: '/admin/operation/data/drainage/order-import',
                            title: '订单批量导入',
                            permissions: ['drainage_order_add'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Drainage/OrderImport')
                            )
                        }
                    ]
                },
                {
                    path: '/admin/operation/data/children-day-2019',
                    title: '2019儿童节活动数据',
                    permissions: ['op_view_children_day_2019'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/Data/ChildrenDay2019')
                    )
                },
                {
                    path: '/admin/operation/data/spo/',
                    title: 'SPO',
                    permissions: ['op_view_spo_floor', 'op_view_spo_apply'],
                    children: [
                        {
                            path: '/admin/operation/data/spo/landing',
                            title: 'SPO 落地页',
                            permissions: ['op_view_spo_floor'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Spo/Landing')
                            )
                        },
                        {
                            path: '/admin/operation/data/spo/apply',
                            title: 'SPO 报名',
                            permissions: ['op_view_spo_apply'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/Spo/Apply')
                            )
                        }
                    ]
                },
                {
                    path: '/admin/operation/data/green-stem',
                    title: 'GreenStem',
                    permissions: ['op_view_green_stem'],
                    children: [
                        {
                            path: '/admin/operation/data/green-stem/feedback',
                            title: '留言反馈',
                            permissions: ['op_view_green_stem'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/GreenStem/Feedback')
                            )
                        },
                        {
                            path: '/admin/operation/data/green-stem/questionnaire',
                            title: '问卷调查',
                            permissions: ['op_view_green_stem'],
                            component: React.lazy(() =>
                                import('~/pages/Admin/Operation/Data/GreenStem/Questionnaire')
                            )
                        }
                    ]
                },
                {
                    path: '/admin/operation/data/mailing/mailing-list',
                    title: '艺术编程硬件包寄件列表',
                    permissions: ['op_view_artpack'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/Data/Mailing/MailingList')
                    )
                },
                {
                    path: '/admin/operation/data/raffles',
                    title: '抽奖活动数据列表',
                    permissions: ['op_view_raffles'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/Data/Raffle')
                    )
                },
                {
                    path: '/admin/operation/data/channel',
                    title: '渠道合作数据统计',
                    permissions: ['channel_cooperation_data'],
                    component: React.lazy(() => 
                        import('~/pages/Admin/Operation/Data/Channel')
                    )
                },
                {
                    path: '/admin/operation/data/groupbuying',
                    title: '拼团活动数据',
                    permissions: ['view_pseudo_group_buying_users'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Data/GroupBuying'))
                },
                {
                    path: '/admin/operation/data/magic_school',
                    title: '魔法馆数据',
                    permissions: ['view_magic_member_data'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Data/MagicHall'))
                },
                {
                    path: '/admin/operation/data/b2b_member',
                    title: '加盟商会员数据',
                    permissions: ['view_all_franchisee_members'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Data/B2bMember'))
                }
            ]
        },
        {
            path: '/admin/operation/user',
            title: '用户管理',
            icon: 'user',
            permissions: ['op_view_last_login', 'op_view_real_name_records'],
            children: [
                {
                    path: '/admin/operation/user/last-login-log',
                    title: '登录信息',
                    permissions: ['op_view_last_login'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/User/LastLoginLog'))
                },
                {
                    path: '/admin/operation/data/attestation',
                    title: '实名认证',
                    permissions: ['op_view_real_name_records'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Data/Attestation'))
                }
            ]
        },
        {
            path: '/admin/operation/qr-code',
            title: '二维码管理',
            icon: 'qrcode',
            permissions: ['op_view_qrcode_course'],
            children: [
                {
                    path: '/admin/operation/qr-code/code-list',
                    title: '二维码列表',
                    permissions: ['op_view_qrcode_course'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/QrCode/NewCode/CodeList')
                    )
                },
                {
                    path: '/admin/operation/qr-code/new-code/data-statistics-list',
                    notMenu: true,
                    title: '数据详情',
                    permissions: ['op_view_qrcode_course'],
                    component: React.lazy(() =>
                        import(
                            '~/pages/Admin/Operation/QrCode/NewCode/DataStatistics/DataStatisticsList'
                        )
                    )
                },
                {
                    path: '/admin/operation/qr-code/new-code/data-statistics',
                    title: '观看记录',
                    notMenu: true,
                    permissions: ['op_view_qrcode_course'],
                    component: React.lazy(() =>
                        import(
                            '~/pages/Admin/Operation/QrCode/NewCode/DataStatistics/WatchingRecords'
                        )
                    )
                },
                {
                    path: '/admin/operation/qr-code/data-statistics-list',
                    title: '数据统计',
                    permissions: ['op_view_qrcode_course'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/QrCode/DataStatistics/DataStatisticsList')
                    )
                },
                {
                    path: '/admin/operation/qr-code/data-statistics',
                    title: '观看记录',
                    notMenu: true,
                    permissions: ['op_view_qrcode_course'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/QrCode/DataStatistics/WatchingRecords')
                    )
                }
            ]
        },
        {
            path: '/admin/operation/article',
            title: '动态管理',
            icon: 'layout',
            permissions: ['op_view_article'],
            children: [
                {
                    path: '/admin/operation/article/list',
                    title: '动态列表',
                    permissions: ['op_view_article'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Article'))
                },
                {
                    path: '/admin/operation/article/editor/5/:id?',
                    title: '新增媒体',
                    notMenu: true,
                    permissions: ['op_update_article', 'op_add_article'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/Article/MediaEditor')
                    )
                },
                {
                    path: '/admin/operation/article/editor/:module_id/:id?',
                    title: '新增动态',
                    notMenu: true,
                    permissions: ['op_update_article', 'op_add_article'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Article/Editor'))
                },
                // {
                //     path: '/admin/operation/article/type',
                //     title: '标签管理',
                //     permissions: ['op_view_article_type'],
                //     component: React.lazy(() => import('~/pages/Admin/Operation/Article/Type'))
                // },
                {
                    path: '/admin/operation/article/keyword',
                    title: '关键词管理',
                    permissions: ['op_view_article_keyword'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Article/Keyword'))
                },
                {
                    path: '/admin/operation/article/module',
                    title: '栏目管理',
                    permissions: ['op_view_article_module'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Article/Module'))
                }
            ]
        },
        {
            path: '/admin/operation/dual-teacher',
            title: '双师课堂',
            icon: 'team',
            permissions: ['op_dbt_class','op_dbt_class_self','op_dbtc_lesson','op_dbtc_lesson_self'],
            children: [
                {
                    path: '/admin/operation/dual-teacher/class-manage',
                    title: '班级管理',
                    permissions: ['op_dbt_class','op_dbt_class_self'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/DualTeacher/classManage')),
                },
                {
                    path: '/admin/operation/dual-teacher/lesson-manage',
                    title: '课时管理',
                    permissions: ['op_dbtc_lesson','op_dbtc_lesson_self'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/DualTeacher/lessonManage')
                    )
                },
            ]
        },
        {
            path: '/admin/operation/iflytek',
            title: '讯飞智课',
            icon: 'tablet',
            permissions: ['zhike_ad', 'zhike_report_teacher', 'zhike_report_student'],
            children: [
                {
                    path: '/admin/operation/iflytek/teacher-ad',
                    title: '教师端广告管理',
                    permissions: ['zhike_ad'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Iflytek/TeacherAd'))
                },
                {
                    path: '/admin/operation/iflytek/teacher-data',
                    title: '教师端数据',
                    permissions: ['zhike_report_teacher'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/Iflytek/TeacherData')
                    )
                },
                {
                    path: '/admin/operation/iflytek/student-data',
                    title: '学生端数据',
                    permissions: ['zhike_report_student'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/Iflytek/StudentData')
                    )
                }
            ]
        },
        {
            path: '/admin/operation/referral_traffic',
            title: '学校引流页管理',
            icon: 'link',
            permissions: ['op_view_referral_traffic'],
            children: [
                {
                    path: '/admin/operation/referral_traffic/index',
                    title: '引流页管理',
                    permissions: ['op_view_referral_traffic'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/ReferralTraffic/ReferralTraffic'))
                },

                {
                    path: `/admin/operation/data/referral_traffic/:tag`,
                    title: '查看详情',
                    permissions: ['op_view_referral_traffic'],
                    component: React.lazy(() =>
                        import('~/pages/Admin/Operation/ReferralTraffic/Detail')
                    ),
                    notMenu: true,
                },
            ]
        },
        {
            path: '/admin/operation/record_referral',
            title: '录播引流课',
            icon: 'laptop',
            permissions: ['drainage_record_data', 'drainage_record_channel_data',
                'drainage_record_normal_data', 'drainage_share_bonus'],
            children: [
                {
                    path: '/admin/operation/record_referral/sign_up',
                    title: '报名表',
                    permissions: ['drainage_record_data', 'drainage_record_channel_data', 'drainage_record_normal_data'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/RecordReferral/SignUp'))
                },
                {
                    path: '/admin/operation/record_referral/share_bonus',
                    title: '瓜分活动数据表',
                    permissions: ['drainage_share_bonus'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/RecordReferral/ShareBonus'))
                }
            ]
        },
        {
            path: '/admin/operation/showcase',
            title: '艺术馆作品管理',
            icon: 'crown',
            permissions: ['coding_gallery_work'],
            children: [
                {
                    path: '/admin/operation/showcase/index',
                    title: '编程艺术馆作品管理',
                    permissions: ['coding_gallery_work'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Showcase/Showcase'))
                }
            ]
        },
        {
            path: '/admin/operation/config',
            title: '配置管理',
            icon: 'setting',
            permissions: ['view_pseudo_group_buying', 'edit_pseudo_group_buying'],
            children: [
                {
                    path: '/admin/operation/config/group_buying',
                    title: '拼团配置',
                    permissions: ['view_pseudo_group_buying', 'edit_pseudo_group_buying'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Config/GroupBuying/index'))
                }
            ]
        },
        {
            path: '/admin/operation/advice',
            title: '投诉建议',
            icon: 'message',
            permissions: ['operate_complain_advice'],
            component: React.lazy(() => import('~/pages/Admin/Operation/Advice/index'))
        },
        {
            path: '/admin/operation/laboratory',
            title: '讯飞AI实验室',
            icon: 'container',
            permissions: ['changyan_list'],
            children: [
                {
                    path: '/admin/operation/laboratory/index',
                    title: '讯飞AI实验室用户表',
                    permissions: ['changyan_list'],
                    component: React.lazy(() => import('~/pages/Admin/Operation/Laboratory/AiLaboratory'))
                }
            ]
        }, 
        {
            path: '/admin/operation/config/lanqiao',
            title: '蓝桥杯赛事',
            icon: 'idcard',
            permissions: ['lanqiao-competition-view'],
            component: React.lazy(() => import('~/pages/Admin/Operation/Config/Lanqiao/index'))
        }
    ]
};
