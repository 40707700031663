/**
 * index
 * Created by john
 * Date: 2019-03-25
 * Email: maiqingqiang@gmail.com
 * Time: 21:47
 */
import React from 'react';
import {DatePicker, Input, Radio, Select} from "antd";
import PropTypes from "prop-types";
import {ActivityCascader, CourseCascader, GroupCascader} from "~/components/Codepku";
import QrCourseCascader from "~/components/Codepku/QrCourseCascader";
import XComponent from "~/components/Common/XComponent";

class XForm extends XComponent {

    render() {
        let options = {};

        const {formType, ...props} = this.props;

        props.key = props.key ? `${props.name}-${props.key}` : props.name;

        let valFieldName = 'val';
        let keyFieldName = 'key';

        if (props.fieldNames) {
            valFieldName = props.fieldNames.val;
            keyFieldName = props.fieldNames.key;
        }

        switch (formType) {
            case 'input':
                return <Input {...props}/>;
            case 'select':
                options = props.options;
                delete props.options;
                return <Select allowClear placeholder='请选择' {...props}>
                    {options.map(value => {
                        return <Select.Option
                            key={value[valFieldName]}>{props.keyRender ? props.keyRender(value) : value[keyFieldName]}</Select.Option>
                    })}
                </Select>;
            case 'radioButton':
                options = props.options;
                delete props.options;
                return <Radio.Group {...props} onChange={(e) => {
                    !props.onChange || props.onChange(e);
                    !props.onSubmit || props.onSubmit(e);
                }}>
                    {options.map(value => {
                        const val = (value[valFieldName] === undefined || value[valFieldName] === null) ? '' : value[valFieldName];
                        return <Radio.Button key={`${valFieldName}-${val}`}
                                             value={val}>{value[keyFieldName]}</Radio.Button>
                    })}
                </Radio.Group>;
            case 'activityCascader':
                return <ActivityCascader {...props}/>;
            case 'courseCascader':
                return <CourseCascader {...props}/>;
            case 'qrCourseCascader':
                return <QrCourseCascader {...props}/>;
            case 'groupCascader':
                return <GroupCascader {...props}/>;
            case 'dateRangePicker':
                return <DatePicker.RangePicker {...props}/>;
            case 'datePicker':
                return <DatePicker {...props}/>;
            case 'custom':
                const {render, ...otherProps} = props;
                return render(otherProps);
            default:
                return <></>
        }
    }
}

XForm.propTypes = {
    formType: PropTypes.string
};

export default XForm;