import http from '~/utils/http';
import {
    ACCOUNT_OWNERS,
    LABELS,
    WECHAT_ACCOUNT_USERS,
    WECHAT_ACCOUNTS,
    WECHAT_GROUP_USERS,
    WECHAT_GROUPS,
    WECHAT_USERS
} from '~/configs/api';

export const LABEL_CATEGORY_WECHAT_GROUP = 1;
export const LABEL_CATEGORY_WECHAT_USER = 2;

/**
 * 获取标签列表
 * @returns {Promise<any>}
 */
export const getLabels = (params = {}) => http.get(LABELS, params);

/**
 * 获取群组-标签
 * @param params
 * @returns {Promise<any>}
 */
export const getLabelsByGroup = (params = {}) => getLabels({category: LABEL_CATEGORY_WECHAT_GROUP, ...params});

/**
 * 获取微信用户-标签
 * @param params
 * @returns {Promise<any>}
 */
export const getLabelsByUser = (params = {}) => getLabels({category: LABEL_CATEGORY_WECHAT_USER, ...params});

/**
 * 获取标签
 * @param id
 * @returns {Promise<any>}
 */
export const getLabelSingle = (id) => http.get(`${LABELS}/${id}/edit`);

/**
 * 新增标签
 * @param data
 * @returns {Promise<any>}
 */
export const createLabel = (data) => http.post(LABELS, data);

/**
 * 更新标签
 * @param id
 * @param data
 * @returns {Promise<any>}
 */
export const updateLabel = (id, data) => http.put(`${LABELS}/${id}`, data);

/**
 * 获取微信账号列表
 * @returns {Promise<any>}
 */
export const getWechatAccounts = (params = {}, all = false) => http.get(WECHAT_ACCOUNTS + (all ? '/true' : ''), params);

/**
 * 获取微信号详情
 * @param id
 * @returns {Promise<any>}
 */
export const getWechatAccountSingle = (id) => http.get(`${WECHAT_ACCOUNTS}/${id}/edit`);

/**
 * 获取微信号的联系人列表
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export const getWechatAccountSingleUsers = (id, params = {}) => http.get(`${WECHAT_ACCOUNTS}/${id}/users`, params);

/**
 * 新增微信号
 * @param data
 * @returns {Promise<any>}
 */
export const createWechatAccount = (data) => http.post(WECHAT_ACCOUNTS, data);

/**
 * 更新微信号
 * @param id
 * @param data
 * @returns {Promise<any>}
 */
export const updateWechatAccount = (id, data) => http.put(`${WECHAT_ACCOUNTS}/${id}`, data);

/**
 * 获取微信群列表
 * @returns {Promise<any>}
 */
export const getWechatGroups = (params = {}, all = false) => http.get(WECHAT_GROUPS + (all ? '/true' : ''), params);

/**
 * 获取微信群级联
 * @returns {Promise<any>}
 */
export const getWechatCasGroups = () => http.get(WECHAT_GROUPS + '/cas');

/**
 * 获取微信群详情
 * @param id
 * @returns {Promise<any>}
 */
export const getWechatGroupSingle = (id) => http.get(`${WECHAT_GROUPS}/${id}/edit`);

/**
 * 获取微信群的群用户
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export const getWechatGroupSingleUsers = (id, params = {}) => http.get(`${WECHAT_GROUPS}/${id}/users`, params);

/**
 * 新增微信群
 * @param data
 * @returns {Promise<any>}
 */
export const createWechatGroup = (data) => http.post(WECHAT_GROUPS, data);

/**
 * 更新微信群
 * @param id
 * @param data
 * @returns {Promise<any>}
 */
export const updateWechatGroup = (id, data) => http.put(`${WECHAT_GROUPS}/${id}`, data);

/**
 * 获取微信用户
 * @returns {Promise<any>}
 */
export const getWechatUsers = (params = {}) => http.get(WECHAT_USERS, params);

/**
 * 导入微信群用户
 * @param data
 * @returns {Promise<any>}
 */
export const importWechatGroupUsers = (data) => http.upload(WECHAT_GROUP_USERS, data);

/**
 * 导入微信好友
 * @param data
 * @returns {Promise<any>}
 */
export const importWechatAccountUsers = (data) => http.upload(WECHAT_ACCOUNT_USERS, data);

/**
 * 获取微信管理员列表
 * @param params
 * @returns {Promise<any>}
 */
export const getAccountOwners = (params = {}) => http.get(ACCOUNT_OWNERS, params);