import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from 'react-router-dom';
import {connect, Provider} from 'react-redux';
import store from '~/store';
import {ConfigProvider} from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import * as codepku from '~/utils/codepku';
import {XLoader} from '~/components';
import moment from 'moment';
import 'moment/locale/zh-cn';
import './index.scss';
import nprogress from 'nprogress';
import {getCommon, getCommonActions} from '~/store/modules/common';
import {getUser} from '~/store/modules/entities/user';
import App from '~/pages/App';
import XComponent from '~/components/Common/XComponent';

moment.locale('zh-cn');

window.codepku = codepku;

const mapStateToProps = state => {
    return {
        common: getCommon(state),
        user: getUser(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        commonActions: getCommonActions(dispatch)
    };
};

@connect(
    mapStateToProps,
    mapDispatchToProps
)
class Main extends XComponent {
    constructor(props) {
        super(props);

        nprogress.start();
    }

    componentDidMount = () => {
        this.props.commonActions.loginRequest();
    };

    render() {
        const {common} = this.props;

        return (
            <>
                {!common.isLogin ? (
                    <div id="first-loading">
                        <XLoader tip="正在全力加载运营中心..." />
                    </div>
                ) : (
                    <Router>
                        <App />
                    </Router>
                )}
            </>
        );
    }
}

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={zh_CN}>
            <Main />
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
