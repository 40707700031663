/**
 * wechatGroup
 * Created by john
 * Date: 2019-07-14
 * Email: maiqingqiang@gmail.com
 * Time: 11:25
 */
import entityFactory from './entityFactory';
import {bindActionCreators} from "redux";

const namespace = 'wechat-group';

export const {types, actions, reducer} = entityFactory(namespace);

export default reducer;

export const getWechatGroup = state => state.entities.wechatGroup;

export const getWechatGroupActions = dispatch => bindActionCreators(actions, dispatch);