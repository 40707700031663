/**
 * user
 * Created by john
 * Date: 2019-07-12
 * Email: maiqingqiang@gmail.com
 * Time: 18:39
 */
import entityFactory from './entityFactory';

const namespace = 'user';

export const {types, actions, reducer} = entityFactory(namespace);

export default reducer;

export const getUser = state => state.entities.user.payload;