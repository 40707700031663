/**
 * 来源组件
 * Created by john
 * Date: 2019-03-28
 * Email: maiqingqiang@gmail.com
 * Time: 18:30
 */
import React, {forwardRef, FunctionComponent, memo, useState} from 'react';
import {Icon, Progress, Upload as Uploader} from 'antd';
import {sliceUploadFile} from '~/utils/cos';
import Moment from 'moment';
import {UploadProps} from 'antd/lib/upload';
import classNames from 'classnames';
import './style.scss';

const Upload: FunctionComponent<
    {placeholder?: string; uploadKey?: (filename: string) => string; value: any} & UploadProps
> = ({placeholder, uploadKey, value, className, ...props}, ref) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);

    const customRequest: (
        option:
            | {
                  action: string;
                  data: {};
                  file: File;
                  filename: string;
                  headers: {};
                  onError: (event: Error, body?: any) => void;
                  onProgress: (event: {percent: number}) => void;
                  onSuccess: (body: any, file?: File) => void;
                  withCredentials: boolean;
              }
            | any
    ) => void = ({file, onProgress, onSuccess, onError}) => {
        setProgress(0);
        setError(null);
        setLoading(false);
        sliceUploadFile({
            Bucket: process.env.REACT_APP_COSV5_BUCKET,
            Region: process.env.REACT_APP_COSV5_REGION,
            Key: uploadKey
                ? uploadKey(file.name)
                : `/operate/${Moment().format('YYYYMMDDhhmmss')}-${file.name}`,
            Body: file,
            onProgress: ({total, loaded}: {total: number; loaded: number}) => {
                const percent = parseFloat(Math.round((loaded / total) * 100).toFixed(2));
                setProgress(percent);
                onProgress({percent});
            }
        })
            .then(response => {
                const url = `https://${response.Location}`;
                onSuccess(url, file);
            })
            .catch(e => {
                setError(e);
                onError(e, file);
            })
            .finally(() => {
                setLoading(false);
            });

        return {
            abort() {
                console.log('上传已终止');
            }
        };
    };

    let file = null;
    let alt = null;
    if (value) {
        if (typeof value === 'string') {
            file = value;
            alt = value;
        } else {
            if (value.file && value.file.response) {
                file = value.file.response;
                alt = value.file.name;
            }
        }
    }

    return (
        <>
            <Uploader
                ref={ref}
                className={classNames('codepku-upload', className)}
                listType="picture-card"
                customRequest={customRequest}
                showUploadList={false}
                {...props}
            >
                {file ? (
                    <img src={file} alt={alt} />
                ) : (
                    <div>
                        <Icon type={loading ? 'loading' : 'plus'} />
                        <div className="ant-upload-text">{placeholder || '选择文件'}</div>
                    </div>
                )}
            </Uploader>

            {progress <= 0 || (
                <Progress
                    percent={progress}
                    size="small"
                    status={
                        value.file && value.file.response
                            ? 'success'
                            : error
                            ? 'exception'
                            : 'active'
                    }
                />
            )}
        </>
    );
};

// @ts-ignore
export default memo(forwardRef(Upload));
