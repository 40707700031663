import nprogress from "nprogress";
import {bindActionCreators} from "redux";
import isEmpty from "lodash/isEmpty";
import wechat from "~/routers/wechat";
import operation from "~/routers/operation";

/**
 * app
 * Created by john
 * Date: 2019-07-12
 * Email: maiqingqiang@gmail.com
 * Time: 18:05
 */
const namespace = 'common';

const initialState = {
    error: null,
    user: null,
    isRequesting: false,
    isLogging: false,
    isLogin: false,
    breadcrumbList: {},
    currentBreadcrumb: []
};

/**
 * type
 * @type {{LOGIN_REQUEST: string, CLEAN_ERROR: string, LOGIN_SUCCESS: string, LOGIN_FAILURE: string}}
 */
export const types = {
    // 登录
    LOGIN_REQUEST: `${namespace}/LOGIN_REQUEST`,
    LOGIN_SUCCESS: `${namespace}/LOGIN_SUCCESS`,
    LOGIN_FAILURE: `${namespace}/LOGIN_FAILURE`,
    // 清除错误
    CLEAN_ERROR: `${namespace}/CLEAN_ERROR`,
    GET_BREADCRUMB: `${namespace}/GET_BREADCRUMB`
};

/**
 * action
 * @type {{loginSuccess: (function(): {type: string}), loginRequest: (function(): {type: string}), loginFailure: (function(*): {type: string, error: *})}}
 */
export const actions = {
    loginRequest: () => ({
        type: types.LOGIN_REQUEST
    }),
    loginSuccess: () => {
        nprogress.done();
        return ({
            type: types.LOGIN_SUCCESS
        })
    },
    loginFailure: (error) => ({
        type: types.LOGIN_FAILURE,
        error
    }),
    getBreadcrumb: (location) => ({
        type: types.GET_BREADCRUMB,
        location
    }),

};

/**
 * action handler
 * @type {{}}
 */
const handers = {
    [types.LOGIN_REQUEST]: (state) => {
        return ({
            ...state,
            isLogging: true
        })
    },
    [types.LOGIN_SUCCESS]: (state) => ({
        ...state,
        isLogging: false,
        isLogin: true
    }),
    [types.LOGIN_FAILURE]: (state, action) => ({
        ...state,
        isLogging: false,
        error: action.error
    }),
    [types.CLEAN_ERROR]: (state) => ({
        ...state,
        error: null
    }),
    [types.GET_BREADCRUMB]: (state, action) => {
        let currentBreadcrumb = state.breadcrumbList[action.location.pathname];
        let breadcrumbList = state.breadcrumbList;

        if (isEmpty(currentBreadcrumb)) {

            currentBreadcrumb = [];

            const routeConf = {
                '/admin/wechat': wechat,
                '/admin/operation': operation
            };

            const urlList = window.codepku.urlToList(action.location.pathname);

            const router = routeConf[action.location.pathname.match(/^(\/\w+){2}/)[0]] || null;

            const currentRoutes = router.children;

            if (!isEmpty(urlList) && !isEmpty(currentRoutes)) {
                const genBreadcrumb = (data) => {
                    data.map(val => {
                        if (urlList.indexOf(val.path) !== -1) {
                            currentBreadcrumb = [...currentBreadcrumb, {
                                breadcrumbName: val.title,
                                path: isEmpty(val.children) ? val.path : null
                            }]
                        }
                        isEmpty(val.children) || genBreadcrumb(val.children)
                        return currentBreadcrumb;
                    });
                };

                genBreadcrumb(currentRoutes);

                breadcrumbList[action.location.pathname] = currentBreadcrumb;
            }
        }

        return {
            ...state,
            currentBreadcrumb,
            breadcrumbList
        }
    }
};

const reducer = (state = initialState, action) => {
    return (handers[action.type] && handers[action.type](state, action)) || state;
};

export default reducer;

/**
 * selects
 */
export const getCommon = state => state.common;

export const getCommonActions = dispatch => bindActionCreators(actions, dispatch);