/**
 * index
 * Created by john
 * Date: 2019-02-28
 * Email: maiqingqiang@gmail.com
 * Time: 16:36
 */
import React from 'react';
import {Card, Collapse, Form, Table} from 'antd';
import XSearch from './XSearch';
import PropTypes from 'prop-types';
import http from '~/utils/http';
import XEditTableContext from './XEditTableContext';
import XEditTableCell from './XEditTableCell';
import {matchPath, withRouter} from 'react-router-dom';
import {parse, stringify} from 'qs';
import XComponent from '~/components/Common/XComponent';
import isEmpty from 'lodash/isEmpty';
import {WhetherIcon} from '~/components/Codepku';
import Zmage from 'react-zmage';
import 'lazysizes';
import {XUrl} from '~/components';

@withRouter
@Form.create()
class XTable extends XComponent {
    static IMAGE = 'image';
    static WHETHER = 'whether';
    static URL = 'url';

    state = {
        loading: false,
        data: [],
        pagination: {},
        filters: {},
        sorter: {},
        searchFormVal: {},
        editKey: ''
    };

    componentDidMount() {
        this.fetch();

        if (!this.props.isModal) {
            this.unlisten = this.props.history.listen(location => {
                const match = matchPath(this.props.history.location.pathname, {
                    path: this.props.match.path
                });

                if (
                    location.pathname === this.props.location.pathname ||
                    (match && match.path === this.props.match.path)
                ) {
                    this.fetch(location);
                }
            });
        }

        // 传递外部可操作函数
        !this.props.onRef ||
            this.props.onRef({
                refresh: this.refresh,
                output: this.xSearchRef ? this.xSearchRef.output : null
            });
    }

    componentWillUnmount() {
        if (!this.props.isModal) {
            this.unlisten();
        }
        this.setState = () => {return};
    }

    fetch = (location = {}) => {
        const query = parse(isEmpty(location) ? this.props.location.search : location.search, {
            ignoreQueryPrefix: true
        });

        this.requst =
            this.props.onRequest ||
            (this.props.requstUrl ? params => http.get(this.props.requstUrl, params) : null);
        if (this.requst) {
            const that = this;

            that.setState({
                loading: true
            });

            !that.props.beforeonRequest || that.props.beforeonRequest();

            return that.requst({
                ...query
            })
                .then(response => {
                    if (that.props.afteronRequest) {
                        let newResponse = that.props.afteronRequest(response);
                        response = newResponse ? newResponse : response;
                    }
                    if (response.code === 200) {
                        const pagination = {...that.state.pagination};
                        if (response.pages) {
                            pagination.pageSize = parseInt(response.pages.per_page);
                            pagination.total = response.pages.total;
                            pagination.current = response.pages.current_page;
                        }
                        that.setState({
                            data: response.data,
                            pagination
                        });
                    }
                })
                .catch(err => {
                    console.error(err);
                    !that.props.erroronRequest || that.props.erroronRequest(err);
                })
                .finally(() => {
                    that.setState({
                        loading: false
                    });
                });
        }
        return Promise.resolve();
    };

    /**
     * 刷新数据
     */
    refresh = (force = false) => {
        if (this.props.isModal) {
            return this.onChange();
        } else {
            return this.fetch();
            // window.location.reload();
        }
    };

    handleRefresh = newQuery => {
        const {location} = this.props;
        const {search, pathname, state} = location;
        const query = parse(search, {
            ignoreQueryPrefix: true
        });

        this.props.history.push({
            state,
            pathname,
            search: stringify({
                ...query,
                ...newQuery
            })
        });
    };

    // /**
    //  * 数据请求对象
    //  * @param params
    //  * @returns {*}
    //  */
    // requst = (params = {}) => {
    //     if (this.props.onRequest) {
    //         return this.props.onRequest(params);
    //     } else if (this.props.requstUrl) {
    //         return http.get(this.props.requstUrl, params);
    //     }
    // };

    /**
     * 数据请求函数
     * @param pagination
     * @param filters
     * @param sorter
     * @param extra
     */
    onChange = (pagination = {}, filters = {}, sorter = {}, extra = {}) => {
        const that = this;
        if (this.props.isModal) {
            const pager = {...that.state.pagination};
            pager.current = pagination.current;

            that.setState({
                pagination: pager,
                filters,
                sorter
            });

            that.setState({
                loading: true
            });

            !that.props.beforeonRequest || that.props.beforeonRequest();

            return that.requst({
                page: pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                per_page: that.props.perPage || 15,
                ...filters
            })
                .then(response => {
                    if (that.props.afteronRequest) {
                        let newResponse = that.props.afteronRequest(response);
                        response = newResponse ? newResponse : response;
                    }
                    if (response.code === 200) {
                        const pagination = {...that.state.pagination};
                        if (response.pages) {
                            pagination.pageSize = parseInt(response.pages.per_page);
                            pagination.total = response.pages.total;
                            pagination.current = response.pages.current_page;
                        }
                        that.setState({
                            data: response.data,
                            pagination
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    !that.props.erroronRequest || that.props.erroronRequest(err);
                })
                .finally(() => {
                    that.setState({
                        loading: false
                    });
                });
        } else {
            that.handleRefresh({
                page: pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                per_page: pagination.pageSize || 15,
                ...filters
            });
            return Promise.resolve();
        }
    };

    render() {
        const that = this;

        let {
            searchs,
            toolbar,
            noCard,
            output,
            outputUrl,
            outputPermissions,
            timeType,
            ...props
        } = that.props;
        props.className = props.className ? props.className + ' x-table' : 'x-table';
        props.rowKey = props.rowKey || 'id';
        props.dataSource = props.dataSource || that.state.data;
        if (props.pagination !== false) {
            props.pagination =
                props.pagination === undefined ? that.state.pagination : {...this.state.pagination, ...props.pagination};
            props.pagination.showSizeChanger = true;
            props.pagination.hideOnSinglePage = false;
            props.pagination.showQuickJumper = true;
            props.pagination.pageSizeOptions = ['10', '15', '20', '30', '40', '50'];
        }

        const query = parse(that.props.location.search, {
            ignoreQueryPrefix: true
        });

        !props.columns ||
            (props.columns = props.columns.map(col => {
                if (col.sorter && col.key === query.sortField) {
                    col.defaultSortOrder = query.sortOrder;
                }

                switch (col.colType) {
                    case XTable.IMAGE:
                        col.render = val => (
                            <div style={{display: 'flex', minHeight: '40px'}}>
                                <Zmage
                                    className="item-image"
                                    src={val}
                                    set={[
                                        {
                                            src: val,
                                            alt: val
                                        }
                                    ]}
                                />
                            </div>
                        );
                        break;
                    case XTable.WHETHER:
                        col.align = 'center';
                        col.render = WhetherIcon.init;
                        break;
                    case XTable.URL:
                        col.render = val => <XUrl url={val} />;
                        break;
                    default:
                }

                return col;
            }));

        if (props.allowEdit) {
            props.components =
                props.components === undefined
                    ? {
                          body: {
                              cell: XEditTableCell
                          }
                      }
                    : props.components;

            !props.columns ||
                (props.columns = props.columns.map(col => {
                    if (!col.editable) {
                        return col;
                    }
                    return {
                        ...col,
                        onCell: record => {
                            return {
                                record,
                                formOptions: col.formOptions,
                                dataIndex: col.dataIndex,
                                title: col.title,
                                editing:
                                    record[props.editRecordKey || 'id'] ===
                                    (props.editRecord
                                        ? props.editRecord[props.editRecordKey || 'id']
                                        : null)
                            };
                        }
                    };
                }));
        }

        props.loading = props.loading || that.state.loading;
        props.onChange =
            props.onChange ||
            ((pagination, filters, sorter, extra) => {
                that.onChange(
                    pagination,
                    {...that.state.filters, ...filters},
                    {...that.state.sorter, ...sorter},
                    extra
                );
            });

        props.scroll = {
            scrollToFirstRowOnChange: true,
            ...props.scroll
        };

        const BoxObject = noCard ? divProps => <div>{divProps.children}</div> : Card;
        return (
            <>
                {!(toolbar || searchs) || (
                    <Collapse
                        className="x-table-toolbar shadowCard borderRadius"
                        expandIconPosition="right"
                        bordered={false}
                        defaultActiveKey={['1', '2']}
                    >
                        {!searchs || (
                            <Collapse.Panel forceRender header="筛选" key="1">
                                <XSearch
                                    onRef={ref => (this.xSearchRef = ref)}
                                    className="x-table-toolbar-item"
                                    output={output}
                                    outputUrl={outputUrl}
                                    items={searchs}
                                    outputPermissions={outputPermissions}
                                    onSearch={props.onChange}
                                    timeType={timeType}
                                />
                            </Collapse.Panel>
                        )}
                        {!toolbar || (
                            <Collapse.Panel forceRender header="操作" key="2">
                                <div className="x-table-toolbar-item x-table-toolbar-operate">
                                    {toolbar}
                                </div>
                            </Collapse.Panel>
                        )}
                    </Collapse>
                )}

                <BoxObject className="shadowCard borderRadius x-table-body">
                    <XEditTableContext.Provider value={this.props.form}>
                        <Table {...props} />
                    </XEditTableContext.Provider>
                </BoxObject>
            </>
        );
    }
}

XTable.propTypes = {
    beforeonRequest: PropTypes.func,
    afteronRequest: PropTypes.func,
    erroronRequest: PropTypes.func,
    onRequest: PropTypes.func,
    requstUrl: PropTypes.string,
    onRef: PropTypes.func,
    editRecordKey: PropTypes.string,
    editRecord: PropTypes.object,
    allowEdit: PropTypes.bool,
    isModal: PropTypes.bool
};

export default XTable;
