/**
 * XEditTableContext
 * Created by john
 * Date: 2019-03-22
 * Email: maiqingqiang@gmail.com
 * Time: 15:50
 */

import React from 'react';

const XEditTableContext = React.createContext();

export default XEditTableContext;