/**
 * 课程组件
 * Created by john
 * Date: 2019-06-28
 * Email: maiqingqiang@gmail.com
 * Time: 18:30
 */
import React from 'react';
import {Cascader} from 'antd';
import isEmpty from 'lodash/isEmpty';
import {getQrCourses} from "~/services/admin/operate/data";
import XComponent from "~/components/Common/XComponent";

class QrCourseCascader extends XComponent {

    state = {
        options: []
    };

    componentDidMount() {
        const that = this;
        getQrCourses(this.props.slug).then(response => {
            let options = response.data.courses.map((item) => {
                if (!isEmpty(item.children)) {
                    return {...item, isLeaf: false}
                }
                return item;
            });

            that.setState({options});
        });
    }

    render() {
        return <Cascader
            placeholder='课程选择'
            options={this.state.options}
            onChange={this.onChange}
            fieldNames={{label: 'title', value: 'id', children: 'chapters'}}
            {...this.props}
        />
    }
}

export default QrCourseCascader;