export const getURLParameters = (param) => {
    const matchs = window.location.href.match(/([^?=&]+)(=([^&]*))/g);
    return matchs ? matchs.reduce((a, v) => (((a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)) , a)), {})[param] || undefined : undefined;
};

export const urlEncode = (param, key, encode) => {
    if (param == null) return '';
    let paramStr = '';
    let t = typeof (param);
    if (t === 'string' || t === 'number' || t === 'boolean') {
        paramStr += '&' + key + '=' + ((encode == null || encode) ? encodeURIComponent(param) : param);
    } else {
        // eslint-disable-next-line no-unused-vars
        for (let i in param) {
            let k = key == null ? i : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
            paramStr += urlEncode(param[i], k, encode)
        }
    }
    return paramStr;
};

export const toDerimal = (x) => {
    if (isNaN(parseFloat(x))) {
    return false;     
    }          
    let f = Math.round(x*100)/100;
    let s = f.toString();
    let rs = s.indexOf('.');
    if (rs < 0) {   
    rs = s.length;      
    s += '.';   
                }       
    while (s.length <= rs + 2) {   
    s += '0';       
    }            
    return s;   
}
