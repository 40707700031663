/**
 * index
 * Created by john
 * Date: 2019-01-27
 * Email: maiqingqiang@gmail.com
 * Time: 15:54
 */
if (process.env.NODE_ENV === 'production') {
    module.exports = require('./store.prod');
} else {
    module.exports = require('./store.dev');
}

