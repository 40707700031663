/**
 * fetch
 * Created by john
 * Date: 2019-07-12
 * Email: maiqingqiang@gmail.com
 * Time: 18:48
 */
import {fork, put, select, takeEvery} from 'redux-saga/effects';

export const type = 'FETCH';

function* fetch({types, service, namespace, pagination, filters, sorter}) {
    // try {
    yield put({type: types.REQUEST});

    const state = yield select(state => state.pages[namespace]);

    window.addEventListener('popstate', function (e) {

        if (e.state) {
            //侦测是用户触发的后退操作, dosomething
            //这里刷新当前 url
            console.log(232222)
        }
    }, false);

    const response = yield service({
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        per_page: 15,
        ...filters
    });

    if (response.code === 200) {
        const pagination = {...state.pagination};
        if (response.pages) {
            pagination.pageSize = parseInt(response.pages.per_page);
            pagination.total = response.pages.total;
            pagination.current = response.pages.current_page;
        }
        yield put({
            type: types.SUCCESS,
            payload: {
                pagination,
                data: response.data
            }
        });
    } else {
        yield put({
            type: types.FAILURE,
            payload: response.message
        });
    }
    // } catch (e) {
    //     yield put({
    //         type: types.FAILURE,
    //         payload: e
    //     });
    // }
}

function* watcher() {
    yield takeEvery(type, fetch)
}

export default fork(watcher)