/**
 * EditableRow
 * Created by john
 * Date: 2019-03-22
 * Email: maiqingqiang@gmail.com
 * Time: 15:42
 */
import React from 'react';
import {Form,} from 'antd';
import XEditTableContext from './XEditTableContext';

const EditTableRow = ({form, index, ...props}) => (
    <XEditTableContext.Provider value={form}>
        <tr {...props} />
    </XEditTableContext.Provider>
);

const EditTableFormRow = Form.create()(EditTableRow);

export default React.memo(EditTableFormRow);