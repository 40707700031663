/**
 * labelWechatGroup
 * Created by john
 * Date: 2019-07-14
 * Email: maiqingqiang@gmail.com
 * Time: 12:54
 */
import entityFactory from './entityFactory';
import {bindActionCreators} from "redux";

const namespace = 'label-wechat-group';

export const {types, actions, reducer} = entityFactory(namespace);

export default reducer;

export const getLabelWechatGroup = state => state.entities.labelWechatGroup;

export const getLabelWechatGroupActions = dispatch => bindActionCreators(actions, dispatch);