/**
 * index
 * Created by john
 * Date: 2019-07-12
 * Email: maiqingqiang@gmail.com
 * Time: 18:08
 */
import {combineReducers} from 'redux';
import user from './user';
import wechatGroup from './wechatGroup';
import accountOwners from './accountOwners';
import labelWechatGroup from './labelWechatGroup';
import activity from './activity';
import course from './course';
import articleModule from './articleModule';
import lanqiaoConfig from './lanqiaoConfig';

export default combineReducers({
    user,
    wechatGroup,
    accountOwners,
    labelWechatGroup,
    activity,
    course,
    articleModule,
    lanqiaoConfig
});