/**
 * XConsole
 * Created by john
 * Date: 2019-03-23
 * Email: maiqingqiang@gmail.com
 * Time: 02:03
 */
import React, {FunctionComponent, memo, ReactNode} from 'react';

const XConsole: FunctionComponent<{ children: ReactNode, type: string }> = ({children, type}) => {
    ((window as any).console[type] || console.log)(children);
    return <>{children}</>
};

export default memo(XConsole);