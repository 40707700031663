/**
 * store.dev
 * Created by john
 * Date: 2019-01-27
 * Email: maiqingqiang@gmail.com
 * Time: 23:26
 */

import {applyMiddleware, createStore} from 'redux';
import rootReducer from './modules';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './middlewares';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(rootSaga);

export default store;