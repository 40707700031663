/**
 * App
 * Created by john
 * Date: 2019-07-16
 * Email: maiqingqiang@gmail.com
 * Time: 20:08
 */
import React from 'react';
import {Modal, Spin} from "antd";
import {XRoute} from "~/components";
import Admin from "~/pages/Admin";
import Login from "~/pages/Login";
import NotPermission from "~/pages/Errors/NotPermission";
import NotFound from "~/pages/Errors/NotFound";
import {Redirect, Switch, withRouter} from 'react-router-dom';
import XComponent from "~/components/Common/XComponent";


@withRouter
class App extends XComponent {

    componentDidMount() {
        this.props.history.listen(() => {
            Modal.destroyAll();
            // Object.keys(sources).forEach(item => {
            //     sources[item]('取消前页面请求')
            // });
            //
            // // eslint-disable-next-line no-unused-vars
            // for (const key in sources) {
            //     !sources[key] || delete sources[key]
            // }
        });
    }

    render() {
        return (<React.Suspense fallback={<Spin style={{
            display: 'flex',
            height: '75vh',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center'
        }} tip="Loading..."/>}>
            <Switch>
                <XRoute exact path="/"
                        render={() => <Redirect to={process.env.REACT_APP_ROUTER_INDEX} push/>}/>
                <XRoute path='/admin' render={(props) => <Admin {...props}/>}/>
                <XRoute path='/login' render={(props) => <Login {...props}/>}/>
                <XRoute path='/403' render={(props) => <NotPermission {...props}/>}/>
                <XRoute render={(props) => <NotFound {...props}/>}/>
            </Switch>
        </React.Suspense>)
    }
}

export default App;
