/**
 * activity
 * Created by john
 * Date: 2019-07-16
 * Email: maiqingqiang@gmail.com
 * Time: 18:21
 */
import entityFactory from './entityFactory';
import {bindActionCreators} from 'redux';

const namespace = 'activity';

export const {types, actions, reducer} = entityFactory(namespace, {payload: []}, {}, {
    [`${namespace}/SUCCESS`]: (state, action) => {
        return {
            ...state,
            isFetching: false,
            isCompleted: true,
            payload: action.payload
        }
    }
}, {
    request: (params = 0) => ({
        type: `${namespace}/REQUEST`,
        params
    }),
    update: (params = 0, targetOption = []) => ({
        type: `${namespace}/UPDATE`,
        params,
        targetOption
    }),
});

export default reducer;

export const getActivity = state => state.entities.activity;

export const getActivityActions = dispatch => bindActionCreators(actions, dispatch);