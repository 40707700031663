/**
 * index
 * Created by john
 * Date: 2019-03-30
 * Email: maiqingqiang@gmail.com
 * Time: 02:52
 */
import React from 'react';
import {Button} from 'antd';
import XComponent from '~/components/Common/XComponent';

class XDownloadButton extends XComponent {
    state = {
        loading: false
    };

    onClick = e => {
        const that = this;
        that.setState({loading: true});
        !that.props.onClick || that.props.onClick(e);
        setTimeout(() => {
            that.setState({loading: false});
        }, 1500);
    };

    render() {
        const {children, ...props} = this.props;

        return (
            <Button icon="download" loading={this.state.loading} {...props} onClick={this.onClick}>
                {children}
            </Button>
        );
    }
}

export default XDownloadButton;
