import React from 'react';
import {BackTop, Layout, Spin} from "antd";
import {Footer, Header, Sider} from '~/components/Layouts';
import routerConf from "~/routers";
import {PrivateRoute as Route, XRoute} from '~/components';
import {Switch} from 'react-router-dom'
import './style.scss';
import NotFound from "~/pages/Errors/NotFound";
import XComponent from "~/components/Common/XComponent";

class Admin extends XComponent {

    state = {
        fixed: false
    };

    componentDidMount() {

    }

    fixedSider = () => {
        const _this = this;
        window.onscroll = function () {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollTop >= 64 && !_this.state.fixed) {
                document.querySelector('.x-sider').classList.add('flxed');
                _this.setState({
                    fixed: true
                });
            } else if (scrollTop < 64 && _this.state.fixed) {
                document.querySelector('.x-sider').classList.remove('flxed');

                _this.setState({
                    fixed: false
                });
            }
        }
    };

    renderRoute(route) {
        return route.map(item => {
            if (item.children) {
                return this.renderRoute(item.children)
            }
            return (
                <XRoute exact={item.exact} key={item.path}
                        path={item.path}
                        permissions={item.permissions}
                        render={() => item.component ? <item.component/> : ''}/>
            )
        });
    }

    render() {
        return (
            <>
                <BackTop target={()=>document.querySelector('.main-content')}/>
                <Layout className='app'>
                    <Header navs={routerConf}/>

                    <Layout>
                        <React.Suspense fallback={<Spin style={{
                            display: 'flex',
                            height: '75vh',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center'
                        }} tip="Loading..."/>}>
                            <Switch>
                                {routerConf.map((item, i) =>
                                    <Route key={i} path={item.path}
                                           render={() => <Sider className='x-sider' menus={item.children}/>}/>
                                )}
                            </Switch>
                        </React.Suspense>
                        <Layout className='main-content'>
                            <React.Suspense fallback={<Spin style={{
                                display: 'flex',
                                height: '75vh',
                                alignItems: 'center',
                                alignContent: 'center',
                                justifyContent: 'center'
                            }} tip="Loading..."/>}>
                                <Switch>
                                    {routerConf.map(item => this.renderRoute(item.children))}
                                    <XRoute render={(props) => <NotFound {...props}/>}/>
                                </Switch>
                            </React.Suspense>
                            <Footer>
                                Copyright ©2019 编玩边学 | 运营中心
                            </Footer>
                        </Layout>
                    </Layout>
                </Layout>
            </>
        );
    }

}

export default Admin;
