/**
 * auth
 * Created by john
 * Date: 2019-07-13
 * Email: maiqingqiang@gmail.com
 * Time: 01:29
 */
import {call, cancelled, fork, put, takeEvery} from 'redux-saga/effects';
import * as commonService from "~/services/common";
import {actions as commonActions, types as commonTypes} from '../modules/common';
import {actions as userActions} from '../modules/entities/user';

/**
 *  登录操作
 * @returns {IterableIterator<(() => CancelledEffect)|CallEffect|PutEffect<*|{type}>>}
 */
function* authorize() {
    try {
        yield put(userActions.request());
        const response = yield call(commonService.fetchMe);
        if (response.code === 200) {
            yield put(userActions.success(response.data));
            yield put(commonActions.loginSuccess());
            // window.location.replace(`#${process.env.REACT_APP_ROUTER_INDEX}`)
        } else {
            yield put(commonActions.loginFailure(response.message));
        }
    } catch (e) {
        yield put(commonActions.loginFailure(e));
    } finally {
        if (yield cancelled) {
        }
    }
}

function* watch() {
    yield takeEvery(commonTypes.LOGIN_REQUEST, authorize)// listen action
}

export default fork(watch)