/**
 * api
 * Created by john
 * Date: 2019-01-26
 * Email: maiqingqiang@gmail.com
 * Time: 09:54
 */


// 运营
export const OPERATE = 'operate';

// 标签
export const LABELS = 'labels';

// 微信账号
export const WECHAT_ACCOUNTS = 'wechat-accounts';

// 微信群
export const WECHAT_GROUPS = 'wechat-groups';

// 微信群用户
export const WECHAT_GROUP_USERS = 'wechat-group-users';

// 微信用户
export const WECHAT_USERS = 'wechat-users';

// 导入微信好友
export const WECHAT_ACCOUNT_USERS = 'wechat-account-users';

// 微信管理员列表
export const ACCOUNT_OWNERS = 'account-owners';

// 活动数据
export const DATA = `${OPERATE}/data`;

// 活动数据
export const USER = `${OPERATE}/user`;

// 公共功能
export const COMMON = 'common';

// 获取登录用户信息
export const ME = 'me';

// 检查是否登录
export const AUTH_CHECK = (process.env.REACT_APP_SSO_HTTPS === 'true' ? 'https' : 'http')
    + '://' + process.env.REACT_APP_SSO_HOST + '/api/auth-check';

// 退出登录
export const LOGOUT = (process.env.REACT_APP_SSO_HTTPS === 'true' ? 'https' : 'http')
    + '://' + process.env.REACT_APP_SSO_HOST + '/logout?redirect_uri=' + encodeURIComponent(window.location.href);

// 文章数据
export const ARTICLE = `${OPERATE}/articles`;

// 讯飞智课广告
export const ZHIKE_AD = `${OPERATE}/zhike-ad`;
