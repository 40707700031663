/**
 * Title: XPage
 * Author: John Mai
 * Email: maiqingqiang@gmail.com
 * Date: 2019-08-13 17:42:01
 */

import React, {FunctionComponent, memo, useEffect} from 'react';
import {XLoader} from '../../index';
import {PageHeader} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {getCommon, getCommonActions} from "~/store/modules/common";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {PageHeaderProps} from "antd/lib/page-header";
import styles from './style/index.module.scss';
import classNames from 'classnames';

const XPage: FunctionComponent<RouteComponentProps & PageHeaderProps & {
    isLoading: boolean,
    hideHeader: boolean,
    headerClassName: string,
    className: string
}> = ({
          location,
          isLoading,
          hideHeader,
          className,
          headerClassName,
          children,
          ...props
      }) => {

    const {currentBreadcrumb} = useSelector(getCommon);
    const dispatch = useDispatch();

    useEffect(() => {
        const commonActions = getCommonActions(dispatch);
        (commonActions as any).getBreadcrumb(location);
    }, [currentBreadcrumb, dispatch, location]);

    return <>
        {isLoading ? <div id="first-loading">
            <XLoader tip="正在加载..."/>
        </div> : <>
            {hideHeader ||
            <PageHeader className={classNames(styles['x-page-header'], headerClassName)}
                        breadcrumb={{routes: currentBreadcrumb}} {...props} />}
            <div className={classNames('x-page', className)} style={{padding: '24px 24px 0 24px'}}>{children}</div>
        </>
        }

    </>
};

export default withRouter(memo(XPage));
