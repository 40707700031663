/**
 * Neditor
 * Created by john
 * Date: 2019-08-02
 * Email: maiqingqiang@gmail.com
 * Time: 11:36
 */
/* eslint-disable*/

import React from "react";
import XComponent from "~/components/Common/XComponent";
import '@notadd/neditor/neditor.all.min.js'

class Neditor extends XComponent {

    componentDidMount() {
        console.log(window.UE)
        const ue = window.UE.getEditor('neditor-container', {
            autoHeight: false
        });
    }

    render() {
        return <script name='name' type='text/plain' id="neditor-container">
        </script>
    }
}

export default Neditor;