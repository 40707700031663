/**
 * logger
 * Created by john
 * Date: 2019-07-14
 * Email: maiqingqiang@gmail.com
 * Time: 15:51
 */

import {fork, select, takeEvery} from 'redux-saga/effects';
import * as Utils from "~/utils";

function* worker(action) {
    console.group(action.type);
    console.log('action:', action);
    console.log('state:', yield select(state => state));
    console.groupEnd();
}

function* watcher() {
    if (process.env.REACT_APP_DEBUG === 'true' || Utils.getURLParameters('codepku_logger') === 'true') {
        yield takeEvery('*', worker);
    }
}

export default fork(watcher)