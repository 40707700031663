import React from 'react';
import {Layout} from 'antd';
import styles from './style.module.scss';

const Footer = (props) => {
    return (
        <Layout.Footer className={styles.footer}>
            {props.children}
        </Layout.Footer>
    );
};

export default React.memo(Footer);
